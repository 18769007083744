import React, { useContext, useEffect, useRef } from "react";
import axios from "axios";

import "./CSS/shaveStyles.css";
import { useAuth } from "../Providers/AuthContext";
import { ShayveState } from "../Providers/ShayveContext";
import AppNav from './Components/AppNav'
import AppHeader from "./Components/AppHeader";
import AppBody from "./Components/AppBody";

const Shayve = () => {
    const { loginWithCoinbase } = useAuth();
    // const state = JSON.parse(localStorage.getItem("shavings"));
    const { popups: {current:popup,set} } = useContext(ShayveState);
    const codeUsed = useRef(false)
    const urlParams = new URLSearchParams(window.location.search);
    const code = urlParams?.get('code');


  function setPopup(argument = null) {
    if(popup !== argument)
    set(argument);
  }
  useEffect(() => {
    let currentFetchUrl
    // Get the URLSearchParams from the current URL


    // Get the 'code' parameter from the URL
    if (code) {
    currentFetchUrl = `https://coinbaseoauthtoken-pbdntqnsna-uc.a.run.app/?code=${code}`
    }
    if (code && !codeUsed.current) {
      // You have the 'code' parameter, you can now use it as needed.
      axios.get(currentFetchUrl)
      .then(({data:{authToken:customToken}})=>{
        if(customToken){
        loginWithCoinbase(customToken)
        .then(()=>{
          window.history.replaceState({}, document.title, '/?authorized=true');
        })
      }
      });
      codeUsed.current = true;
      // console.log(code)
    }


    // Optionally, you can remove the query parameters from the URL
    // if you no longer need them in the address bar.
  }, []);

  return (
    code ? <h1>You've signed up! Logging you in....</h1> :
    <div className="flex justify-center items-center h-[100vh]">
    <div id="app-wrapper" className=" w-full sm:w-[640px] h-[100vh] flex flex-col">
      <AppHeader />
      <AppBody />
      <AppNav setPopup={setPopup}/>
    </div>
    </div>
  );
};

export default Shayve;
