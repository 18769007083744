import React, { useEffect, useRef, useState, createContext, useReducer } from 'react';
import AppReducer,{ACTIONS} from '../Pages/SCRIPTS/AppReducer'
import axios from 'axios'
import BabyShayveState from '../Pages/Components/Vars/shayve_classes';
import { db } from '../firebase';
import { doc, getDoc, setDoc, serverTimestamp, deleteDoc, query, collection, getDocs, addDoc } from "firebase/firestore";
import { useAuth } from './AuthContext';
import PopupReducer, {POPUPS} from '../Pages/SCRIPTS/PopupReducer';
import buildCharges, { BUILD_CHARGES_ERRORS } from '../Pages/SCRIPTS/buildCharges';
// import { newInput } from '../Pages/SCRIPTS/CMD';
let ShayveState
// Provider componenet
export const ShayveProvider = ({ children }) => {
  // localStorage.setItem('charge_hash_temp',"[]")
  // let init_charge_hashes = JSON.parse(localStorage.getItem('charge_hash_temp'))
  // let oldInitState = JSON.parse(localStorage.getItem('shavings'));
  let initState
  let localState = JSON.parse(localStorage.getItem('shayve0.1.0'));
  if (!localState) initState = JSON.parse(JSON.stringify(new BabyShayveState()))
  else initState = localState
  if(!initState.inputs) initState.inputs = []
  if(initState && !initState?.profile?.uid && initState?.profile?.uid !== null) {
    initState.profile = {uid:null};}
  // const charges = {
  //   charge_sums: [],
  //   charge_hashes: [],
  //   charges_above_thresh: []
  // }
  // initState.charges = charges
  // console.log(initState.charges)

  const [state, dispatch] = useReducer(AppReducer, initState);
  const [popup, dispatchPopup] = useReducer(PopupReducer, POPUPS.navHome)
  const [loaded, setIsLoaded] = useState(false)
  const [newInput, setNewInput] = useState({value: null, needsToPost: false});
  const [deletedInput, setDeletedInput] = useState({value: null, needsToPost: false});
  const [hasNewUpdate, setHasNewUpdate] = useState(false)
  const [inputsNeedToPush, setPushInputs] = useState(false)
  // const [fieldToChange, setFieldToChange] = useState({})

  const [globalFromValue, setGlobalFromValue] = useState('');

  let stateDocRef
  let uid;
  let email;
  // Create Global context 
  ShayveState = createContext(initState);

  // Define dispatch functions
  function addInput(newInput) {
    dispatch({
        type: ACTIONS.ADD_INPUT,
        payload: { newInput: newInput.value }
    });
    console.log(`dispatch: Input added`)
  }
  function deleteInput(deletedInput) {
      dispatch({
          type: ACTIONS.DELETE_INPUT,
          payload: { inputId: deletedInput.value }
      });
      console.log(`dispatch: Input deleted`)
  }
  function saveState(newState) {
      dispatch({
          type: ACTIONS.UPDATE_STATE,
          payload: { newState }
      });
      setHasNewUpdate(true) //Triggers posts to firestore
      console.log(`dispatch: Updates posted`)
  }
  function saveStateLocal(newState) {
    dispatch({
        type: ACTIONS.UPDATE_STATE,
        payload: { newState }
    });
}
  function setPopup(popupAction=null){
    dispatchPopup({
      type: popupAction,
      payload: null
    })
    // console.log('Popup changed:', popupAction?popupAction:'popups closed')
  }

  const {currentUser, fetchState, setFetchState} = useAuth();


  async function fetchFirestoreState(passedState){
    //Fetching initState from Cloud Firestore
    //saves newer state locally
    return new Promise((resolve)=>{
    try{
    //Different ways of fetching UID
    // console.log(initState)
    if (currentUser){
      // console.log(currentUser)
      uid = currentUser.uid
      email = currentUser.email

      if (uid !== passedState.profile.uid){
        console.log("User changed uid, since their last state")
        const {inputs, ...initState} = passedState
        initState.profile.uid = uid
        initState.profile.email = email
        //Context: user logs into another account which conflicts with their localStorage
        //clear the local storage
      }
    }
    else if (passedState?.profile?.uid !== null) {
      console.log("Alert: Local inputs cleared. Log in to retrieve!")
      // console.log(passedState)
      uid = null
      email = null
      const {inputs, ...initState} = passedState
      initState.profile.uid = null
      initState.profile.email = null
      initState.inputs = []
      saveStateLocal(initState)
    } else {
      uid = null
      email = null
      const {inputs, ...initState} = passedState
      initState.profile.uid = null
      initState.profile.email = null
      initState.inputs = []
      saveStateLocal(initState)
    }

    if(uid){
      stateDocRef = doc(db, "users",uid,"shayve","state")
      const inputsCollectionRef = collection(stateDocRef,"inputs")
      getDoc(stateDocRef)
      .then((snapshot)=>{
        if(snapshot.exists()) {
        return snapshot.data()
        } else { //if snapshot doesn't exit (newly created account)
          if (initState.profile.uid === null) {
          //push local inputs collection to firestore
            const initState=passedState
            initState.profile.uid = uid
            initState.profile.email = email
            if(initState.inputs.length > 0)
            setPushInputs(true) //ONly push the inputs if uid is null
            saveState(initState)
            console.log("Anonymous user has been assigned a uid.")
          } 
          else if (initState?.profile.uid) {
            const {inputs,...initState} = passedState
            initState.profile.uid = uid
            initState.profile.email = email
            initState.inputs = []
            saveState(initState)
            console.log("Existing user has switched to a fresh account")
          }
          console.log("Brand new user. No firestore data available")
          setIsLoaded(true)
        }
      })
      .then((snapshotData)=>{
        const testProfile = snapshotData.profile
        if (testProfile) {
          getDocs(query(inputsCollectionRef))
          .then((querySnapshot)=>{
            const querySnapshotArray = querySnapshot.docs
            const firestoreInputs = [];
            (async () => {
              for (const document of querySnapshotArray) {
                firestoreInputs.push(document.data());
              }
            })()
            .then(()=>{ //inputs have been downloaded from server
              console.log("Inputs have been downloaded from the server")
              firestoreInputs.sort((a,b)=>{
                return (b.created_at.seconds * 1000 + b.created_at.nanoseconds / 1000) - (a.created_at.seconds * 1000 + a.created_at.nanoseconds / 1000);
              })
              initState = {
                ...snapshotData,
                inputs: firestoreInputs
              };
              saveStateLocal(initState)
              resolve(initState)
            })
          })
        }
      })
      .catch((error)=>{
        console.error("ShayveContext: Error fetching state with getDoc", error)
        if(!initState) {initState = new BabyShayveState(uid?uid:null)}
        // console.log(initState)
        resolve(initState)
      })
      .finally(()=>{
        if(state?.inputs?.length !== initState?.inputs?.length) {
          dispatch({type: ACTIONS.UPDATE_STATE, payload: {newState:initState}})
          const jsonString = JSON.stringify(initState)
          localStorage.setItem("shayve0.1.0",jsonString)
          // console.log("newest snapshot loaded from firestore!")
        }
      })
    } else {
      //Unauthenticated Baby User
      resolve(initState)
    }
    } catch (uidError) {
      console.error("Error fetching currentUser.uid", uidError)
      if(!initState) {initState = new BabyShayveState(uid?uid:null)}
      // console.log(initState)
      resolve(initState)
    }});
  }
  async function updateFirestoreState(passedNewInput,passedState){
    // console.log(passedState)
    let uid
    let stateDocRef
    if (currentUser){
      uid = currentUser.uid
    }
    else if (initState) {
    uid = state.profile.uid
    }
    else {
    uid = null
    }
    if(uid){
    stateDocRef = doc(db, "users",uid,"shayve","state");
    if (newInput.needsToPost || deletedInput.needsToPost || hasNewUpdate) {
      // const jsonString = JSON.stringify(state)
      // localStorage.setItem("shayve0.1.0",jsonString)
      try {
      //update the user's inputs in firestore with new shaving
      let fieldToChange
      if (passedNewInput.needsToPost){
      const newInput = {
        ...(JSON.parse(JSON.stringify(passedNewInput.value))),
        created_at: serverTimestamp()
      }
      const newInputRef = doc(stateDocRef,'inputs',passedNewInput.value.inp_id)
      setDoc(newInputRef,newInput)
        .then(async ()=>{
          let currentInputs = passedState.inputs
          let inputIndex
          currentInputs.find((input,index)=>{
            if(input.inp_id = newInput.inp_id){
              inputIndex = index
              return true
            }
            return false
          })
          const newInputDoc = await getDoc(newInputRef)
          const newInputWithDate = newInputDoc.data()

          currentInputs[inputIndex] = newInputWithDate

          const currentState = {
            ...passedState,
            inputs: currentInputs
          }

          //keep working on this logic
            //first, check user_balance (fetch)

          const fetchBal = async (uid,email,asset) => {
            const url = 'https://checkcoinbasebalance-pbdntqnsna-uc.a.run.app'
            const data = {
              uid:uid,
              email:email,
              asset
            }
            try {
              const response = await axios.post(url,data)
              return response.data
            } catch (error) {
              console.error(error.message)
            }
          }
          // let USDCBal = await fetchBal(currentUser?.uid,currentUser?.email,"USDC")
          let userBal = null
          if (passedState.profile.settings.funding_src.type === 'fiat_account' /*|| state.prof...use_usd === true */)
          userBal = await fetchBal(currentUser?.uid,currentUser?.email,"USD")

          // console.log(userBal)
          //if it's less than charge_thresh RETURN (skip building charges, it's pointless!)
          if (passedState.profile.settings.funding_src.type === 'fiat_account' && userBal < passedState.profile.investment_schedule.charge_min) 
          {return} //guardClause
          buildCharges(currentState)
          .then((buildCharges_result)=>{
            const {updatedState,chargesAboveThresh} = buildCharges_result
            const stateWithHashes = updatedState
            saveState(stateWithHashes) //post to firestore. (Here or later?)
            //YES, HERE because
            //this would be a new step: user-state never previously had "charges"
            //now it does for running recurCheck and dotwCheck (server-side)
            const stateToFetch = {
              ...stateWithHashes,
              charges: {
                inputs: stateWithHashes.charges.inputs,
                sums: stateWithHashes.charges.sums.filter((_,index)=>{
                return chargesAboveThresh.indexOf(index) !== -1;
              })
            }
            };
          //Save the resulting charge_hashes to localStorage
          // init_charge_hashes = init_charge_hashes ?? []
          // const currentChargesPending = init_charge_hashes.concat(charge_hashes)
          // localStorage.setItem('charge_hash_temp',JSON.stringify(currentChargesPending))

        //guard clause
        //if the user's settings are not on charge immediately, then don't call the invest endpoint!!
        if (passedState.profile.investment_schedule.charge_date === "DOTW") {
          const userDotw = passedState.profile.investment_schedule.dotw.day
          const hasPendingCharges = chargesAboveThresh.length > 0
          if(currentUser)
          currentUser.getIdToken()
          .then((token)=>{
            const dotwUrl = 'https://adddotwuser-cijqzg662q-uc.a.run.app'
            const queryUrl = dotwUrl+'?uid='+currentUser.uid+'&dotw='+userDotw+'&charges_above_thresh='+hasPendingCharges
            const headers = {
              "Authorization": `Bearer ${token}`
            }
            axios.get(queryUrl,{headers})
            .then((response)=>{
              console.log(response.data) //Success!
            })
          })
          .catch(()=>{

          });
          else return
        }
        // console.log(chargesAboveThresh)
        
        if(chargesAboveThresh.length===0) return // guard clause for now (don't wanna fetch yet)

          //now we'll send buildCharges_results (destructured) to a buildOrders endpoint 
          // YOU'RE HERE 👇 (Refactor the Fetch Logic, to receive the right Variables)
          // 📍
          const fetchData = {state:stateToFetch,uid:currentUser?.uid,email:currentUser?.email}
          const fetchUrl = 'https://investinputsep-pbdntqnsna-uc.a.run.app'
          const fetchOptions = {
            method:"POST",
            body:JSON.stringify(fetchData),
            headers:{
              'Content-type':'application/json'
            }
        }

        //instead of popping the hashes with a response array, it should now just get done server-side
        //within the input.targets themselves!!

        //else, FETCH ON!!
        //this is where we invest any investable charges, the equivalent would be done on the server-side for DOTW & recurrings
        //so take notes
        fetch(fetchUrl,fetchOptions)
        .then((ep_response)=>{
          if(ep_response){ //HANDLE RARE CASE WHERE INPUTS RETURN SUCCESSFULLY INVESTED
            //BUT FIRESTORE UPDATE FAILES
            // console.log("investInputsEp:",`[${ep_response.status}]`,ep_response.body)
          //instead of popping the hashes locally, download the updated state??

          //...and load the most recent stateinputs from firestore
            //before ever calling this endpoint again!!
            const stateDocRef = doc(db,"users",uid,"shayve","state")
            getDoc(stateDocRef)
            .then((docSnap)=>{
              if (!docSnap.exists) throw new Error(`investInputsEp: user's state document not found on firestore. Failed, or doesn't exist.`)
              const newState = docSnap.data()

              const inputsCollectionRef = collection(stateDocRef,"inputs")
              getDocs(query(inputsCollectionRef))
              .then((querySnapshot)=>{
                const querySnapshotArray = querySnapshot.docs
                const firestoreInputs = [];
                (async () => {
                  for (const document of querySnapshotArray) {
                    firestoreInputs.push(document.data());
                  }
                })()
                .then(()=>{ //inputs have been downloaded from server (date updated)
                  firestoreInputs.sort((a,b)=>{
                    return (b.created_at.seconds * 1000 + b.created_at.nanoseconds / 1000) - (a.created_at.seconds * 1000 + a.created_at.nanoseconds / 1000);
                  })
                  initState = {
                    ...newState,
                    inputs: firestoreInputs
                  };
                  initState.charges = []//buildCharges_result //saving the charges (with sum) for recursion
                  saveStateLocal(initState)
                })
              })
            })
          }
        })
        .catch((err)=>{
          console.error(err)
          //save the ambiguous inputs to charge_hashes_flagged
        })
      }).catch((error)=>
      {
        if (error.message === BUILD_CHARGES_ERRORS.NO_NEW_INPUTS_TO_INVEST){
          console.log('Now new inputs to invest!')
        }
        else {
          console.error(error)
        }
      })
      })
      // console.log('new input is posting')
      }
      else if (deletedInput.needsToPost){
      const inputRef = doc(stateDocRef,'inputs',deletedInput.value)
      deleteDoc(inputRef)
      // console.log('input needs to be deleted')
      }
      else if (hasNewUpdate){
      const { inputs, ...state } = initState;
      //For now set the whole doc state.
        //But later get more specific with fieldToChange state
      if(fieldToChange){}
      else {
      setDoc(stateDocRef,state);
      if(inputsNeedToPush){
        const inputsCollectionRef = collection(stateDocRef,"inputs")
        inputs.forEach((inputObj)=>{
          addDoc(inputsCollectionRef,inputObj)
          .then(()=>{
            "Document has been added successfully"
          })
          .catch((error)=>{
            console.error("Error adding document:", error)
          })
        })
      } else {
      // console.log('state updated')
      }
      }
      }
      } catch (error) {
      console.error(error);
      } finally {
      setNewInput({value: null, needsToPost: false });
      setDeletedInput({value: null, needsToPost: false });
      setHasNewUpdate(false)
      }
    }
  }
  };

  //Run the initState check on firestore
  const firestoreLoaded = useRef(false)
  useEffect(()=>{
    if(!firestoreLoaded.current /* | fetchState */){
    fetchFirestoreState(state) //buggy code (executes twice when fetchState initializes twice)
    .then(()=>{
      setIsLoaded(true)
      setFetchState(false)
    })
    firestoreLoaded.current = true
    }
    updateFirestoreState(newInput,state); 
    //Does the above statement conflict with buildChr..?
    
  },[newInput, fetchState, deletedInput, hasNewUpdate, state])

  return (<ShayveState.Provider value={{
      state,
      popups: {current: popup, set: setPopup},
      shavings: null, //state.shavings,
      settings: null, //state.settings,
      add: { newInput, setNewInput, globalFromValue, setGlobalFromValue },
      remove: { deletedInput, setDeletedInput },
      deleteInput,
      addInput,
      saveState // (pushes state to server for save)
  }}>
      {loaded && children}
  </ShayveState.Provider>)
}
// Export Global context 
export {ShayveState}