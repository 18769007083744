
export class ShayveInput_input_amount_value{
    constructor(amt_saved=null, amt_toinvest=null){
        this.total=amt_saved
        this.toinvest=amt_toinvest
    }
}
export class ShayveInput_input_type_value{
    constructor(saved_type="MONEY",subcategory=null){
        this.saved_type=saved_type
        this.subcategory=subcategory
    }
}

export class ShayveInput_recurs_every_value{
    constructor(remaining_times=12,time_unit="MONTH",unit_multiplier=1){
        this.remaining_times=remaining_times
        this.time_unit=time_unit
        this.unit_multiplier=unit_multiplier
    }
}

const getSavingsHashtag = (expense_name) => {
    //in this function use REGEX to get the hashtag out of the expense hashtag
    return null
}
const getSavingsName = (expense_name) => {
    //in this function use REGEX to get the hashtag out of the expense name
    return expense_name
}

export class ShayveInput_saved_from_value{
    constructor(expense_name, by='unspecified'){
        this.name=
        {
            val: expense_name?getSavingsName():"unspecified",
            by
        }
        this.hashtag=expense_name?getSavingsHashtag():null
    }
}

export class ShayveInput_should_invest_value{
    constructor(charge_timing="ON_DATE",count=0,first="NOW"){ //charge_timing can be "ON_DATE" or "ON_SCHEDULE" //first should eventually be a serverTimestamp() date
        this.charge_timing=charge_timing
        this.count=count
        this.first=first
    }
}

export class ShayveInput_spread_acr_value{ //will just require additional division/calculation on the server cronJob
    constructor(total_installments=4,spread_unit="INPUTS",unit_multiplier=4){ //spread_unit can also be DOTW (max values = 4)
        this.total_installments=total_installments //please use to increment the timesInvested value (divide 1 by installments)
        this.spread_unit=spread_unit
        this.unit_multiplier=unit_multiplier
    }
}

const NewShayveInput = {
    amt: ShayveInput_input_amount_value,
    input_type: ShayveInput_input_type_value,
    recurs_every: ShayveInput_recurs_every_value,
    saved_from: ShayveInput_saved_from_value,
    should_invest: ShayveInput_should_invest_value,
    spread_acr: ShayveInput_spread_acr_value
}

export default NewShayveInput