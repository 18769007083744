import React, { useState, useContext } from 'react';
import { ShayveState } from '../../../Providers/ShayveContext';
import { VALUE } from '../Vars/shayve_classes';
import { POPUPS } from '../../SCRIPTS/PopupReducer';
import { useAuth } from '../../../Providers/AuthContext';
import { DropdownContext } from '../../../Providers/AlertContext';

const ContinueInput = ({newInput, setNewInput}) => {
    const { addInput, state, popups } = useContext(ShayveState)
    const {setAlert} = useContext(DropdownContext)
    const {currentUser}=useAuth()
    const currency = state?.profile?.currency ?? {symbol:"$",ticker:"USD"}
    const gasPrice = state?.profile?.settings?.gas_price?.[1]??4
    const mpg = state?.profile?.vehicles?.user?.[state?.profile?.vehicles?.default]?.mpg ?? 25;
    // console.log(state?.profile?.settings?.gas_price?.[1])
    const [fromInputValue,setFromInputValue] = useState('')
    const [subtypesMenu,showSubtypesMenu] = useState(false)
    // console.log(subtypesMenu)
    const togglePopup = () => {
        popups.set(POPUPS.navHome)
    };
    // const toggleSubtype = () => {
    //     showSubtypesMenu(true)
    // };


    const [isFormCompleted, setIsFormCompleted] = useState(true);
    
    const [rangeSliderValue, setRangeSliderValue] = useState(0)
    const [subtype, setSubtype] = useState(null)

    // const [isInFocus, setIsInFocused] = useState(false)

    const handleFromChange = (e) => {
        setFromInputValue(e.target.value);
        //setIsFormCompleted(e.target.value !== ''); //eventually combine with a button or alert that says to "put a description!"
        // console.log(fromInputValue)
    }

    const handleSlide = (e) => {
        setRangeSliderValue(e.target.value)
    }


    // let newSubtype
    // const handleRadioButtonChange = (event) => {
    //     newSubtype = event.target.value
    //     // Check if one of the radio buttons is selected
    //     setIsFormCompleted(event.target.value !== '');
    //     // console.log(event.target.value)
    //     setSubtype(newSubtype);
    //   };

    const handleSubmit = (event) => {
        event.preventDefault(); // Prevents the default form submission
        // Your custom form submission logic goes here
        // For example, you can use Axios or fetch to send form data to the server
        const fromInputValueArray = (function splitStringByHashtag(inputString) {
            let fromFirstPart = null;
            let fromSecondPart = null;
          
            if (inputString.includes('#')) {
              const parts = inputString.split('/#/');
              fromFirstPart = parts[0];
              fromSecondPart = parts[1];
            } else {
              fromFirstPart = inputString!==''?inputString:'not specified';
            }
          
            return [ fromFirstPart, fromSecondPart ];
          })(fromInputValue);

        newInput.value.inp_type.subcategory = subtype;
        newInput.value.saved_from.name.val = fromInputValueArray[0];
        newInput.value.saved_from.hashtag = fromInputValueArray[1];
        if (newInput.value.inp_type.saved_type === VALUE.MILES) {
            newInput.value.amt.toinvest = Math.floor((rangeSliderValue/100) * newInput.value.amt.total/mpg*gasPrice*1000)/1000;
        } else {
        newInput.value.amt.toinvest = (rangeSliderValue/100) * newInput.value.amt.total;
        }
        if(newInput.value.amt.toinvest>0){
            newInput.value.should_invest.count = 1;
            newInput.value.targets.forEach((target,targetIndex)=>{
                newInput.value.targets[targetIndex].to_invest = target.fraction_of_input * newInput.value.amt.toinvest
            })
        };
        if (isFormCompleted && newInput.value.amt.toinvest >= 0) {
            togglePopup(POPUPS.navHome);
            setNewInput({ ...newInput, needsToPost: true });
            // console.log(newInput.value)
            addInput(newInput)
            //.then....
            setAlert({value:true,message:
                newInput.value.amt.toinvest > 0 ?
                `Success: Amount of ${currency.symbol}${newInput.value.amt.toinvest}${!currency.symbol ? ' '+currency.ticker:''} will be invested.`
                :
                `Done. Shayve won't invest this one.`
                ,type:"alert-success"
                })
            setFromInputValue('');
            setIsFormCompleted(false);
          };
      };

    return (
        <>
        {(popups?.current === POPUPS.createInput) ? (
        <>
        <div id='mny-popup-div' className="z-1 absolute w-screen h-screen bg-[#a1a1a1] flex justify-center">
            <form onSubmit={handleSubmit} className='z-2 bg-[#F0F0F0] w-[95%] px-[25%] h-[440px] flex flex-col items-center justify-center self-center relative'>
            <span className="top-2 pl-4 text-left mb-4 text-lg absolute w-full" id="close-popup" onClick={togglePopup}>&times; <u className='text-sm h-full inline-block translate-y-[-1px]'>close</u></span>
                <label htmlFor='invest-slider' className={`font-semibold text-xl  ${!currentUser ? '': "w-[60%]"}`}>{!currentUser?"Log in to start investing & more":"Set aside how much?"}</label>
                <div className='flex flex-col mt-3'>
                    {!currentUser&&<span className='text-gray-400'>Example: </span>}<span className={`text-4xl font-semibold ${!currentUser && 'text-gray-400'}`}>{!currentUser ? `$1.27` :rangeSliderValue === '0' ? `$0` : `$${newInput.value.inp_type.saved_type === VALUE.MONEY ? ((rangeSliderValue/100) * newInput.value.amt.total).toFixed(2) : ((rangeSliderValue/100) * newInput.value.amt.total/mpg * gasPrice).toFixed(2)}`}</span>
                    <span className={`text-md ${rangeSliderValue==='100'&&"after:content-['_✨']"}`}>{rangeSliderValue === '0' ? `nothing...` : ''}{rangeSliderValue > 0 && rangeSliderValue < 100 ? `${rangeSliderValue}% of $${newInput.value.inp_type.saved_type === VALUE.MONEY?newInput.value.amt.total:(newInput.value.amt.total/mpg * gasPrice).toFixed(2)}`:rangeSliderValue === '100' ? `full amount` : ''}</span>
                </div>
                <input id='invest-slider' className={`w-[80%] ${currentUser? 'mt-4':'mt-2 mb-2'}`} type='range' value={currentUser?rangeSliderValue:100} onChange={handleSlide} min={0} max={100} step={1} disabled={!currentUser}/>
                {!currentUser
                &&
                <>
                <button className='w-[140%] mt-2 border-2 px-3 py-2 bg-[#ffae00] text-white text-sm font-semibold rounded-full' disabled>Log in / sign up with Coinbase</button>
                <hr className='mt-4 w-full col-span-3 '/>
                </>
                }
                {!currentUser&&<h1 className='mt-4 mb-2 text-xl w-[125%]'>Or continue with basic:</h1>}
                <h2 className=''>{newInput.value.inp_type.saved_type === VALUE.MONEY?'Item description':'Commute description 🚗'}</h2>
                <input className='from-input text-center py-1 rounded-md mt-2' maxLength={100} type='text' value={fromInputValue} id='from' placeholder='describe' onChange={handleFromChange}/>
                <span className='border-2 py-1 px-3 mt-3 rounded-md bg-[#4469ff] font-semibold text-white'><input type='submit' /* onClick={toggleSubtype} */ value={rangeSliderValue>0?'Invest it': fromInputValue!=='' ? 'Done':!currentUser?'Done':'Skip'} /></span>

                {/* <Madlib/> */}
            </form>
        </div>
        {subtypesMenu || (popups?.current === POPUPS.createInput) &&  <div className='bg-[#0000005e] absolute h-screen w-screen z-[-1]'/>}

        </>
        ):null}
        </>
    );
}

export default ContinueInput;