import React, { createContext, useState } from 'react';

export const DropdownContext = createContext(null)

const AlertProvider = ({children}) => {
    const [alert,setAlert] = useState({value:false,message:"",type:""})

    const values = {
        alert,
        setAlert
    }
    return (
        <DropdownContext.Provider value={values}>
            {children}
        </DropdownContext.Provider>
    );
}

export default AlertProvider;