import React from 'react';
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Shayve from './Pages/ShayveApp';
import { AuthProvider } from './Providers/AuthContext';
import { ShayveProvider } from './Providers/ShayveContext';
import AlertProvider from './Providers/AlertContext';

// import PrivateRoute from './Pages/Components/PrivateRoute';
import './index.css'

function App() {
  return (
  <Router>
    <AuthProvider>
    <AlertProvider>
    <ShayveProvider>
        <Routes>
          
            <Route path='/*' element={<Shayve/>}/>
          
        </Routes>
    </ShayveProvider>
    </AlertProvider>
    </AuthProvider>
  </Router>
  );
}

export default App;
