import React, {useContext, useState } from 'react';
import { ShayveState } from '../../../Providers/ShayveContext';
import EditInputPopup from './EditInput';
import { VALUE } from '../Vars/shayve_classes';


const Input = ({ input }) => {
    const { remove: { setDeletedInput }, deleteInput } = useContext(ShayveState);
    // const userId = state?.profile?.uid

    const [toggleEditInput, setToggleEditInput] = useState(false)

    const handleDeleteInput = () => {
        const inputId = {value: input.inp_id, needsToPost: true}
        // console.log(inputId)
        deleteInput(inputId)
        setDeletedInput(inputId)
    }

/*     function hasDecimalPlaces(number) {
        return number.toString().includes('.');
    } */

      const dateOptions = { year: 'numeric', month: '2-digit', day: '2-digit' };
      const dateCalc = (new Date(input?.created_at?.seconds * 1000 + input?.created_at?.nanoseconds/1000)).toLocaleDateString(undefined, dateOptions)
    return (
        <>
        <div className='gli'>
        {/* =<li className='madlist-item'>
            I shaved <span className={shaving.typeSaved[0]==='Money' ? 'money' : 'miles'}>{shaving.typeSaved[0]==='Money' && shaving.amts[0] >= 1 ? '$' : ''}{shaving.amts[0] < 1 ? `${shaving.amts[0]*100}¢` : shaving.amts[0] > 1 && hasDecimalPlaces(shaving.amts[0]) ? shaving.amts[0].toFixed(2) : shaving.amts[0] } {shaving.typeSaved[0]==='Miles' ? ' miles': ''} </span> off of my <b>{shaving.from}</b> {shaving.typeSaved[0]==='Money'? 'expense!' : 'commute!'} on {shaving.dateCreated}  {shaving.from === '' ? <button className='update-from-btn'>+</button> : ''}<button className='delete-btn' onClick={() => handleDeleteShaving({value: Input.Inputid, needsToPost: true}, userId)}>x</button><button className='share-btn'>share</button>
        </li> */}
        <br/>
        <span>{dateCalc!=='Invalid Date' ? dateCalc: 'loading...'}</span>
        <span>{input.inp_type.saved_type=== VALUE.MONEY ? '$' : ''}{input.amt.total}{input.inp_type.saved_type===VALUE.MILES?' miles':''}</span>
        <span>{input.saved_from?.name?.val}</span>
        <span>{input.amt.toinvest ? '$'+parseFloat((input.amt.toinvest*input.times_invested).toFixed(2))+' / $'+parseFloat((input.amt.toinvest*1).toFixed(2)):"(doesn't invest)"}</span>
        <span>{/* <button onClick={() => {setToggleEditInput(!toggleEditInput)}}>+</button> */}
              <button onClick={handleDeleteInput}>x</button></span>
        </div>
        {toggleEditInput ? <EditInputPopup input={input} inputId={input.inp_id} setToggleEditInput={setToggleEditInput}/> : null}
        </>
    );
}

export default Input;