import React, { useState, useContext } from 'react';
import { useAuth } from '../../Providers/AuthContext';
import axios from 'axios';
import DropdownAlert from '../../UI/DropdownAlert';
import { DropdownContext } from '../../Providers/AlertContext';

const WaitList = () => {
    const {currentUser} = useAuth()
    const email = currentUser?.email ?? 'acool@email.com'
    const [success,setSuccess] = useState(false)
    const [inWaitlist,setInWaitlist] = useState(false)
    const [error,setError] = useState(false)
    const {alert,setAlert:setShowAlert} = useContext(DropdownContext)
    if(currentUser)
    currentUser.getIdTokenResult()
    .then((idTokenResult)=>{
      let in_waitlist = idTokenResult.claims.in_waitlist
      setInWaitlist(in_waitlist)
    })

    //Need an alert: "Your account has been created." when you reach this module
    const loadAlert = (message,isSuccess) => {
        // console.log('success: '+isSuccess+' '+message)
        setShowAlert({value:true,message,type:isSuccess?'alert-success':'alert-error'})
    }

    const handleSubmit = async (e) => {
        e.preventDefault()
        const url = `https://emaillistep1-pbdntqnsna-uc.a.run.app/?submission=invitation_waitlist`
        const data = {
            email,
            data: {
                invited:false
            }
        }
            try {
                currentUser.getIdToken(true)
                .then((idToken)=>{
                    const headers = {'Authorization': 'Bearer '+idToken}
                    //add their email to a list... hmmm 🤔
                    //well I guess, whenever a non-whitelisted user signs up,
                    //it could add them to a list of authed users. Or I guess... In their user doc
                    //or when a whitelisted user logs in and confirms their email it could add them to a list
                    //now when they ACCEPT to receive promotions. A field on their object could
                    axios.post(url,data,{headers})
                    .then(response=>{
                        // console.log(response)
                        if (response.status === 200)
                        loadAlert('Success!',true)
                        setSuccess(true)
                    })
                })
            } catch (error) {
                console.error(error)
                loadAlert('Failed: '+error.message,false)
                setError(true)
            }

    }

    return (
        <div className='relative h-full bg-[buttonface] flex flex-col justify-center'>
            {/* {!success? */}
            <div className='flex flex-col items-center justify-stretch'>
            <iframe className='mb-4 sm:mb-0 sm:h-[220px] sm:w-[440]' src="https://www.youtube.com/embed/iPlRFpSkcMc?si=xFlriQet8ofS0FRI" title="YouTube video player" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowFullScreen></iframe>
            <form onSubmit={handleSubmit} className='h-full flex flex-col items-center justify-center'>
                {alert.value && <DropdownAlert isWaitlist={true}/>}
                <h1 className='text-2xl w-3/4 font-semibold sm:w-1/3 text-center'>Shayve requires an Invitation.</h1>

                <p className='mt-2'>Your email:{/* <span className={`${checked && 'line-through'}`}>{email}</span> */}</p>
                <p><span className='text-xl'>{email}</span></p>
                {(success||inWaitlist) ?
                <span className='text-gray-500 text-lg'> Pending approval! 😝</span>
                :error?
                <span className='text-red-500 text-lg'> Application error 😕</span>
                :
                <span className='text-red-500 text-lg'> not invited yet 👇</span>}
                {/* <hr className='w-[50%] mt-4 mb-3'/> */}
                {/* <div className='mt-1 text-lg font-semibold'></div> */}

                <div className='mt-1'><button type='submit' className='mt-2 mb-1 text-[#fff] text-xl bg-blue-600 disabled:bg-gray-300 disabled:text-gray-200 disabled:scale-80 px-2 py-2 rounded-md' disabled={success||inWaitlist||error}>Request invitation{/* Click to join waitlist */}</button></div>
                {(success||inWaitlist) &&<p className='text-[#4caf50] w-1/2 text-center'>You've been added to the waitlist!</p>}
                <div className='w-1/3 mt-2 flex justify-center'><label className='text-sm text-center text-gray-400'>{!(success||inWaitlist)?<><span>receive updates & promotions</span><input checked disabled type='checkbox'/></>:null}</label></div>
            </form>
            </div>
{/*             :
            <div className='self-center'>Success</div> 
            }*/}
        </div>
    );
}

export default WaitList;