import React, {useContext} from 'react';
import InputForm from './Components/HomeComps/InputForm';
import ContinueInput from './Components/HomeComps/CreateInputPopup';
import { ShayveState } from '../Providers/ShayveContext';



const Home = () => {
    const { add } = useContext(ShayveState)
    return (
        <>
        <section id="app-body" className="bg-[buttonface] flex-grow border-x-2 text-center">
        <div className="grid-bois">
        <div className="w-full h-full translate-y-[-0.5em] flex flex-col justify-center items-center">
        <InputForm/>
        <ContinueInput newInput={add.newInput} setNewInput={add.setNewInput} setGlobalFromValue={add.setGlobalFromValue} globalFromValue={add.globalFromValue}/>
        </div>
        </div>
        </section>
        </>

    );
}

export default Home;