export const ACTIONS = {
    ADD_INPUT: 'ADD_SHAVING',
    DELETE_INPUT: 'DELETE_SHAVING',
    UPDATE_STATE: 'SAVE_STATE'
}

const AppReducer = (state, action) => {
    switch(action.type) {
        case ACTIONS.DELETE_INPUT:
            const reducedState = {
                ...state,
                inputs: state.inputs.filter(input => input.inp_id !== action.payload.inputId)   
            };
            //update state locally for non_authenticated users
            const reducedStateJson = JSON.stringify(reducedState)
            localStorage.setItem("shayve0.1.0",reducedStateJson)
            return reducedState
        case ACTIONS.ADD_INPUT:
            let newInput = action.payload.newInput
            const utcDate = Date.parse(new Date().toUTCString())
            const seconds = Math.floor(utcDate / 1000); // Convert milliseconds to seconds
            const nanoseconds = (utcDate % 1000) * 1000000;
            const firestoreTime = {
                seconds,
                nanoseconds,
              };

            if(!state?.profile?.uid){
                newInput.created_at = firestoreTime
            }
            let biggerState = {
                    ...state,
                    inputs: [newInput, ...state.inputs]
                };
        //update state locally for non_authenticated users
        const biggerStateJson = JSON.stringify(biggerState)
        localStorage.setItem("shayve0.1.0",biggerStateJson)
            return biggerState;
        case ACTIONS.UPDATE_STATE:
            const updatedState = action.payload.newState
            // console.log(`ShayveReducer: state context updated`)
        //update state locally for non_authenticated users
        const updatedStateJson = JSON.stringify(updatedState)
        // console.log(updatedState)
        localStorage.setItem("shayve0.1.0",updatedStateJson)
            return updatedState
        default:
            return state;
    }
}
export default AppReducer