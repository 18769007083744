import { nanoid as uuidv4 } from 'nanoid'
import {Profile} from "./profile_args"


import { ShayveInput_input_amount_value, ShayveInput_input_type_value, ShayveInput_recurs_every_value, ShayveInput_saved_from_value, ShayveInput_should_invest_value } from "./input_args"

//Default State Example (a newborn state 👶)
export class BabyShayveState{
    constructor(uid){
        this.profile = new Profile(uid)
        this.inputs = []
        this.charges = {inputs:[],sums:[]}
    }
};

const getGasPrice = (state) => {
    return state?.profile?.settings?.gas_price?.[1] ?? 0
}
export const VALUE = {
    MONEY: "MONEY",
    MILES: "MILES",
    DEFAULT: "DEFAULT"
}

class ShayveInput{
    constructor(
        state, amt=new ShayveInput_input_amount_value(), 
        inp_uuidv4=uuidv4(), inp_type=new ShayveInput_input_type_value(), 
        recurs_every=null, saved_from=new ShayveInput_saved_from_value(), 
        targets=null, times_invested=0
        ){
        this.amt = amt
        this.car_selected = inp_type.saved_type === VALUE.MILES ? state?.profile?.vehicles?.user?.[state?.profile?.vehicles?.default] ?? 0 : 0        
        this.inp_id = inp_uuidv4
        this.inp_type = inp_type
        this.gas_price = inp_type.saved_type === VALUE.MILES ? getGasPrice(state):null
        this.recurs_every = recurs_every === VALUE.DEFAULT?new ShayveInput_recurs_every_value():null
        this.saved_from = saved_from
        this.should_invest = new ShayveInput_should_invest_value(state?.profile?.investment_schedule?.charge_date === "IMMEDIATELY"||state?.profile?.investment_schedule?.charge_date === "DAILY"? "ON_DATE":"ON_SCHEDULE",amt.toinvest>0?1:0)
        this.targets = targets ? targets : [{
            for_input_id:inp_uuidv4,for_target_id:VALUE.DEFAULT,to_invest:amt.toinvest,fraction_of_input:1,is_filled:false,
            asset:state?.profile?.targets.user[0].asset.value,platform:state?.profile?.targets.user[0].asset.exchange
        }]
        this.times_invested = times_invested
        this.spread_acr = null
    }
}

const _ = undefined
const useDefault = "DEFAULT"
export const ShayveInputBundle = {_, useDefault, ShayveInput}
export default BabyShayveState