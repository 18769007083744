export const POPUPS = {
    createInput: "Opening: Input confirmation popup",
    selectSubtype: "Opening: Subtype selection menu",
    navHome: "Nav: Going home.",
    navInputs: "Nav: Showing input history.",
    navProfile: "Nav: Showing profile."
}

const PopupReducer = (state,action) => {
    switch(action.type) {
        case POPUPS.createInput:
            // console.log(POPUPS.createInput)
            return POPUPS.createInput
        case POPUPS.selectSubtype:
            return POPUPS.selectSubtype
        case POPUPS.navHome:
            return POPUPS.navHome
        case POPUPS.navInputs:
            return POPUPS.navInputs
        case POPUPS.navProfile:
            return POPUPS.navProfile
        default:
            return null
    }
    
};

export default PopupReducer;