import { useState } from 'react';
import { POPUPS } from '../SCRIPTS/PopupReducer';
import { useAuth } from '../../Providers/AuthContext';
const {navHome,navInputs,navProfile} = POPUPS;


const AppNav = ({setPopup}) => {
  const {currentUser} = useAuth();
  const [userIsWhitelisted,setUserIsWhitelisted] = useState(false)
  if(currentUser)
  currentUser.getIdTokenResult()
  .then((idTokenResult)=>{
    setUserIsWhitelisted(idTokenResult.claims.whitelisted)
  })
  // const whitelisted = currentUser?.customClaims['whitelisted']
  return (
    <section id="app-nav" className=" text-center flex justify-between bg-[buttonface] border-b-2 py-[1.25rem]">
      {userIsWhitelisted?
      <><button onClick={()=>setPopup(navHome)} className=" text-[24px] w-[2em] h-[2em] mx-[1.5em]">🏠</button>
      <button onClick={()=>setPopup(navInputs)} className=" text-[24px] w-[2em] h-[2em] mx-[1.5em]">📊</button>
      <button onClick={()=>setPopup(navProfile)} className=" text-[24px] w-[2em] h-[2em] mx-[1.5em]">👤</button>
      </>
      :
      <div className='flex ml-2 gap-4 text-sm w-full justify-center'>
      <a className='underline' href='https://shayve.us'>About us</a>
      <a className='underline' href='https://twitter.com/shayvefinance'>Twitter</a>
      <a className='underline' href='https://www.youtube.com/channel/UChdPJXNwFYQA9gPsaDyEGLA'>YouTube</a>
      </div>
      }
    </section>
  );
};

export default AppNav;
