const investment_schedule = {
    charge_date: "IMMEDIATELY", //"IMMEDIATELY", or "DOTW"
    charge_min: 10,
    charge_max: 100,
    dotw: {
        day: "MONDAY",
        time: 0 //adjusted for server
    }
};
const settings = {
    gas_price: ["AUTO", 4], //should be calculated automatically from "AUTO", "MANUAL" or "location" (but [1] can be set manually). If location services available, will fall to backup (manually set value)
    time_zone: null,
    withdrawal_accounts: [],
    currency:{symbol:"$",ticker:"USD"},
    funding_src: {id:null,label:"Select an option"},
    fiat_acct_id: null
};
const targets = {
    default: 0,
    user: [{
        nickname: 'default',
        goal: 0,
        asset: {
            exchange: "COINBASE",
            value: "USDC-USD",
            invest_from: "DEFAULT"
        },
        count_existing_investments: false,
        dont_invest: true,
        action_on_reached: {
            val: null, //"SELL_AND_WITHDRAW", "JUST_SELL", "BUY_GIFT", "SELL_AND_BUY_GIFT", "WITHDRAW_AND_BUY_GIFT"
            funding_src: "DEFAULT",
            delay: null //can delay the action to give time for withdrawals (by default will wait for the sale)
        },
        repeat_goal: {
            number_of_times: 0,
            repeat_action: true
        }
    }]
};
const vehicles = {
    default:0,
    user:[
        {uuid: 'example_entry', mpg: 22, nickname: 'Honda Pilot 2003'}]
};

export class Profile {
    constructor(uid=null){
    this.uid = uid
    this.investment_schedule = investment_schedule
    this.settings = settings
    this.targets = targets
    this.vehicles = vehicles
}
};