import React, { useState, useEffect, useContext } from 'react';
import { DropdownContext } from '../Providers/AlertContext';
const DropdownAlert = (props) => {
    const [isVisible, setIsVisible] = useState(false);
    const {alert,setAlert:setShowAlert} = useContext(DropdownContext) //value in alert should really be "show"
    const {message,type:css_class} = alert
    const {isWaitlist} = props


    useEffect(() => {
        // Show the alert when it's mounted
        if(message){
        setIsVisible(true);
        // console.log(type)

        const hideAgain = setTimeout(()=>{
            setIsVisible(false);
        },2850)
    
        // Hide the alert after 3 seconds (adjust as needed)
        const timeoutId = setTimeout(() => {
          setShowAlert({value:false,message:''})
        }, 3000);
    
        // Clear the timeout when the component is unmounted
        return () => {
            clearTimeout(hideAgain)
            clearTimeout(timeoutId)
        };
        }
      }, [alert]);
    return (
        <div className={`text-[#fff] w-[350px] absolute ${!isWaitlist ? "-top-4" : "top-2"} text-center p-[10px] m-[10px] rounded border-[#fff] border-1 font-bold transition ${isVisible ? 'block opacity-100' : 'opacity-[0]'} ${css_class}`}>
            {message}
        </div>
    );
}

export default DropdownAlert;