import React, {useContext, useState, useEffect } from 'react';
import { auth } from '../firebase';
import { createUserWithEmailAndPassword, onAuthStateChanged, sendPasswordResetEmail, signInWithEmailAndPassword, signOut, updateEmail, updatePassword, signInWithCustomToken } from 'firebase/auth';

const AuthContext = React.createContext()

export function useAuth() {
    return useContext(AuthContext)
}

const AuthProvider = ({ children }) => {
    const [currentUser, setCurrentUser] = useState()
    const [loading, setLoading] = useState(true)
    const [fetchState,setFetchState] = useState(false)

    let user = null;
    async function signup(email, password){
        return createUserWithEmailAndPassword(auth, email, password)
        .then((userCredential)=>{
            user = userCredential.user;
            // console.log(user)
        })
        .catch((error) => {
            const errorCode = error.code;
            const errorMessage = error.message;
            console.log(errorCode,':',errorMessage)
          });
    }
    async function login(email, password){
        return signInWithEmailAndPassword(auth, email, password)
        .then((userCredential)=>{
            user = userCredential.user;
            // console.log(user)
        })
        .catch((error) => {
            const errorCode = error.code;
            const errorMessage = error.message;
            console.log(errorCode,':',errorMessage)
          });
    }
    async function loginWithCoinbase(token){
        return signInWithCustomToken(auth,token)
        .then((userCredential)=>{
            user = userCredential.user;
        })
        .catch((error) => {
            const errorCode = error.code;
            const errorMessage = error.message;
            console.log(errorCode,':',errorMessage)
          });
    }
    async function logout(){
        return signOut(auth)
        .catch(error=>{
            console.error(error)
        })
    }
    async function resetPassword(email){
        return sendPasswordResetEmail(auth, email)
        .then((/* res */)=>{
            // console.log(res)
        })
        .catch((error)=>{
            console.error(error)
        })
    }
    async function updateUserEmail(email){
        return updateEmail(currentUser, email)
        .then((/* res */)=>{
            // console.log(res)
        })
        .catch((error)=>{
            console.error(error)
        })
    }
    async function updateUserPassword(newPassword){
        return updatePassword(currentUser, newPassword)
        .then((/* res */)=>{
            // console.log(res)
        })
        .catch((error)=>{
            console.error(error)
        })
    }

    useEffect(() => {
        const unsubscribe = onAuthStateChanged(auth, (user) => {
            setCurrentUser(user)
            setLoading(false)
            setFetchState(true)
        })
        return unsubscribe
    }, [])



    const value = {
        currentUser,
        fetchState,
        signup,
        login,
        loginWithCoinbase,
        logout,
        resetPassword,
        updateUserEmail,
        updateUserPassword,
        setFetchState
    }
    return (
        <AuthContext.Provider value={value}>
            {!loading && children}
        </AuthContext.Provider>
    );
}

export { AuthProvider };