import { Routes, Route } from "react-router-dom";

import Home from "../Home";
import Inputs from "../Inputs";
import Profile from "../Profile";

import { POPUPS } from "../SCRIPTS/PopupReducer";
import { ShayveState } from "../../Providers/ShayveContext";
import { useContext, useState } from "react";
import SettingsProvider from "../../Providers/SettingsContext";
import { useAuth } from "../../Providers/AuthContext";
import WaitList from './WaitList.js';
import DropdownAlert from "../../UI/DropdownAlert";

const {navHome,navInputs,navProfile,createInput,selectSubtype} = POPUPS

const AppBody = () => {
  const [userIsWhitelisted,setUserIsWhitelisted] = useState(false)
  const {popups:{current:Page}} = useContext(ShayveState);
  const {currentUser} = useAuth()
  if(currentUser)
  currentUser.getIdTokenResult()
  .then((idTokenResult)=>{
    setUserIsWhitelisted(idTokenResult.claims.whitelisted)
  })
  return (
    <>
    <div className="flex justify-center relative">
    <DropdownAlert/>
    </div>
    <Routes>
      {/* <Route path="/" element={<Home/>}/>
      <Route path="/savings" element={<Inputs/>}/>
      <Route path="/profile" element={<Profile/>}/> */}
      <Route
        path="/*"
        element={
          userIsWhitelisted ?
          (Page === navHome || Page === createInput || Page === selectSubtype ? <Home /> :
          Page === navInputs ? <Inputs /> :
          Page === navProfile ? <SettingsProvider><Profile /></SettingsProvider> : <Home />)
          :
          currentUser ?
          <WaitList/>
          :
          (<SettingsProvider><Profile /></SettingsProvider>)
        }
      />
    </Routes>
    </>
  );
};

export default AppBody;
