import { useState, useReducer,useContext } from "react";
import axios from "axios";
import { ShayveState } from "../Providers/ShayveContext";
import { useAuth } from "../Providers/AuthContext";
// import {ReactSVG as SVG} from 'react-svg'
// import ArrowUpSVG from "./arrow-up.svg"
import SelectOption from "./Components/ProfileComps/SelectOption";
import { ChevronUpIcon, PlusSmallIcon } from "@heroicons/react/20/solid";
import { SettingsContext } from "../Providers/SettingsContext";

export const ProfilePopups = {
    INVESTMENT_PREFS:"Investment Preferences",
    VEHICLE_PREFS:"Vehicle Preferences",
    TARGET_PREFS:"Target Preferences"
}
export const Cases = {
    ASSET_SELECTION: "ASSET_SELECTION",
    FUNDING_SELECTION: "FUNDING_SELECTION",
    INVESTMENT_SCHEDULE: "INVESTMENT_SCHEDULE"
}

export const ProfileReducer = (prevState,action) => {
    switch(action.type) {
        case ProfilePopups.INVESTMENT_PREFS:
            // console.log("Show:", ProfilePopups.INVESTMENT_PREFS)
            return ProfilePopups.INVESTMENT_PREFS
        case ProfilePopups.VEHICLE_PREFS:
            // console.log("Show:", ProfilePopups.VEHICLE_PREFS)
            return ProfilePopups.VEHICLE_PREFS
        case ProfilePopups.TARGET_PREFS:
            // console.log("Show:", ProfilePopups.TARGET_PREFS)
            return ProfilePopups.TARGET_PREFS
        default:
            return null
    }
}
//More Components
const CreateNewVehicle = ({handleNewVehicle}) => {
    function handleSubmit(e){
        e.preventDefault()
    }
    return (
        <>
            <h3 className="text-xl flex-1">Create a Vehicle</h3>
        <form id="create-vehicle-pop" onSubmit={handleSubmit} className="flex flex-col items-center">
            <input type="text" placeholder="mpg"/>
            <input type="text" placeholder="year"/>
            <input type="text" placeholder="make"/>
            <input type="text" placeholder="model"/>
            <input type="text" placeholder="nickname"/>
            <span className="w-full flex justify-center relative">
            <input type="submit" value={"add vehicle"}/><input type="button" value={"cancel"} className="" onClick={handleNewVehicle}/>
            </span>
        </form>
        </>
    );
}

const CreateNewTarget = ({handleNewTarget}) => {
    function handleSubmit(e){
        e.preventDefault()
    }
    return (
        <>
            <h3 className="text-xl flex-1">Create a Target</h3>
        <form id="create-target-pop" onSubmit={handleSubmit} className="flex flex-col items-center">
            <input type="text" placeholder="mpg"/>
            <input type="text" placeholder="year"/>
            <input type="text" placeholder="make"/>
            <input type="text" placeholder="model"/>
            <input type="text" placeholder="nickname"/>
            <span className="w-full flex justify-center relative">
            <input type="submit" value={"add vehicle"}/><input type="button" value={"cancel"} className="" onClick={handleNewTarget}/>
            </span>
        </form>
        </>
    );
}


//Profile Forms
const ProfileSettings = () => {
    const {currentUser} = useAuth()
    const {schedule:selectedValue,selectedAsset,
        selectedPaymentMethod, chargeMin,chargeMax,saveEnabled:{current:saveEnabled,set:setSaveEnabled},
        initSPM,initChargeMin,initChargeMax,saveSettings} = useContext(SettingsContext)

    const [paymentOptions,setPaymentOptions] = useState([initSPM,{label:'Options loading...', value:null, isDivider:true, unavailable:true}])
    const [optionsLoaded,setOptionsLoaded] = useState(false)

    //const [selectedGasOpt,setSelectedGasOpt] = useState('');
    const options = [
        { label: 'Immediately:', value: null, isDivider: true },
        { label: 'Every new input (above threshold)', value: 'IMMEDIATELY', isDivider: false },
        { label: 'On specific days:', value: null, isDivider: true },
        { label: 'Every Monday', value: 'MONDAY', isDivider: false },
        { label: 'Every Tuesday', value: 'TUESDAY', isDivider: false },
        { label: 'Every Wednesday', value: 'WEDNESDAY', isDivider: false },
        { label: 'Every Thursday', value: 'THURSDAY', isDivider: false },
        { label: 'Every Friday', value: 'FRIDAY', isDivider: false },
        { label: 'Every Saturday', value: 'SATURDAY', isDivider: false },
        { label: 'Every Sunday', value: 'SUNDAY', isDivider: false },
      ];
      const assetOptions = [
        { label: "Bitcoin (BTC)", value: "BTC-USDC", isDivider: false },
        { label: "Ethereum (ETH)", value: "ETH-USDC", unavailable: true },
        { label: "USDC/USD", value: "USDC", isDivider: false }
      ]

      const handleChargeMinChange = (e) => {
        const inputValue = e.target.value;
        
        // Use a regex pattern to allow only numbers
        const numberPattern = /^[0-9]*$/;
    
        if (numberPattern.test(inputValue) || inputValue === '') {
          // Update state only if the input is a number or empty
          chargeMin.set( parseFloat(inputValue) ? parseFloat(inputValue):'');
        }
        chargeMin.set(parseFloat(inputValue)?parseFloat(inputValue):'')
        // checkEnableSave(parseFloat(inputValue)?parseFloat(inputValue):'',chargeMax.current,selectedPaymentMethod,selectedAsset,selectedValue)
      };
      const handleChargeMaxChange = (e) => {
        const inputValue = e.target.value;
        
        // Use a regex pattern to allow only numbers
        const numberPattern = /^[0-9]*$/;
    
        if (numberPattern.test(inputValue) || inputValue === '') {
          // Update state only if the input is a number or empty
          chargeMax.set( parseFloat(inputValue)? parseFloat(inputValue):'');
        }
        chargeMax.set(parseFloat(inputValue)?parseFloat(inputValue):'')
        // checkEnableSave(chargeMin.current,parseFloat(inputValue)?parseFloat(inputValue):'',selectedPaymentMethod,selectedAsset,selectedValue)
      };

      const loadPaymentOptions = () => {
        // e.preventDefault()
        const paymentMethodsURL = "https://listcoinbasepaymentmethods-pbdntqnsna-uc.a.run.app"
        const data = {
            uid:currentUser.uid,
            email:currentUser.email
          }
        const dataPromise = axios.post(paymentMethodsURL,data)
        dataPromise
        .then(({data:userPaymentMethods})=>{
            const noPaymentMethods = {label:'No other payment methods found. Add a Bank/Card to Coinbase to see it here!', value:"NONE_FOUND", isDivider:true, unavailable:true}
            setPaymentOptions(!userPaymentMethods.some((option)=>option.type!=="fiat_account")?[userPaymentMethods.find(option=>option.type==='fiat_account'),noPaymentMethods]:userPaymentMethods);setOptionsLoaded(true)
        })
      }

    const handleSubmit = async (e) => {
        e.preventDefault()
        saveSettings(ProfilePopups.INVESTMENT_PREFS)
        //clear fields
        chargeMin.set('')
        chargeMax.set('')
        setSaveEnabled(false)
    }
    return (
        <form onSubmit={handleSubmit} className="flex flex-col w-full items-center col-span-full">
            <h1 className="text-xl mb-2">Set-aside/Investing Settings</h1>
            <h2 className="text-lg">Select an asset to sayve:</h2>
            <SelectOption options={assetOptions} loadPaymentOptions={loadPaymentOptions} setOptionsLoaded={setOptionsLoaded} selectedValue={selectedAsset.current} setSelectedValue={selectedAsset.set} useWholeOption={false} caseType={Cases.ASSET_SELECTION}/>
            {selectedAsset.current === "USDC-USD" && <span className="flex mt-2">Auto-convert USDC to fiat? :<input className="ml-2" type="checkbox"/></span>}
            <h2 className="text-lg mt-4">Select Funding Source:</h2>
            <SelectOption options={paymentOptions} loadPaymentOptions={loadPaymentOptions} optionsLoaded={optionsLoaded} setOptionsLoaded={setOptionsLoaded} selectedValue={selectedPaymentMethod.current} setSelectedValue={selectedPaymentMethod.set} useWholeOption={true} caseType={Cases.FUNDING_SELECTION}/> 
            <div className="flex flex-col w-full mt-4 mb-2">
                <span className="grid grid-cols-2">
                    <label className="place-self-end">Payment threshold ($):</label>
                    <input type="text" placeholder={initChargeMin} value={chargeMin.current} onChange={handleChargeMinChange}  className="inline-block w-20 ml-5 text-center"/>
                </span>
                <span className="grid grid-cols-2">
                    <label className="place-self-end">Maximum payment ($):</label>
                    <input type="text" placeholder={initChargeMax} value={chargeMax.current} onChange={handleChargeMaxChange} className="inline-block w-20 ml-5 text-center"/>
                </span>
            </div>
            <div className="flex flex-col w-full items-center">
                <label className="text-lg mt-3">When to set-aside/invest:</label>
                {/* <select className="w-[50%]" value={selectedValue} onChange={handleDropdownChange}>
                {options.map((option) => {
                    return option.isDivider ? (
                        <optgroup label={option.label} key={option.value} />
                    ) : (
                        <option value={option.value} key={option.value}>
                        {option.label}
                        </option>
                    );
                })}
                </select> */}
                <SelectOption options={options} loadPaymentOptions={loadPaymentOptions} setOptionsLoaded={setOptionsLoaded} selectedValue={selectedValue.current} setSelectedValue={selectedValue.set} useWholeOption={false} caseType={Cases.INVESTMENT_SCHEDULE}/>
                {/* <small className="mt-2 w-3/4">Only up to the maximum amount you set will be invested each time.</small> */}
            </div>
            {/* <h1 className="text-xl">For Gas prices</h1>
            <div className="flex flex-col w-full">
                <span className="flex flex-col w-full items-center">
                    <label className="text-lg">Calculate gas price</label>
                    <select value={selectedGasOpt} onChange={(e) => setSelectedGasOpt(e.target.value)} className="w-[50%]">
                        <option value={''}>Using nearest prices 📍</option>
                        <option value={'fixedPrice'}>Use a fixed price</option>
                    </select>
                </span>
                {selectedGasOpt==="fixedPrice"&&<span className="grid grid-cols-2">
                    <label className="place-self-end">Fixed gas price:</label>
                    <input type="text" placeholder="4" className="inline-block w-20 ml-5"/>
                </span>}
            </div> */}
            <input type="submit" value={"Save"} className="bg-[#4b75ff] text-[white] rounded-lg font-semibold px-2 py-1 disabled:bg-[#f7f7f7] w-[60px] mt-3 disabled:text-gray-200" disabled={!saveEnabled}/>
            {saveEnabled && <small className="mt-2 w-3/4">New inputs will only invest once the sum has reached your set-aside threshold.</small>}

        </form>
    );
}
const ProfileVehicles = () => {
    const [addNewVehicle,setAddNewVehicle] = useState(false);
    const handleNewVehicle = (e) =>{
        if (e.target.value === "Add new")
        {setAddNewVehicle(true)}
        else if (e.target.value === "cancel")
        {setAddNewVehicle(false)}
        console.log(addNewVehicle)
    }
    return (
        <div className="flex flex-col w-full col-span-full">
                <h3 className="text-xl">Your vehicles</h3>

            <div className="bg-white">
                <ul>
                    <li>vehicle1 <input type="button" value={"edit"}/> <input type="button" value={"x"}/></li>
                </ul>
            </div>
            <input type="button" value={"Add new"} onClick={handleNewVehicle}/>
            {addNewVehicle && <CreateNewVehicle handleNewVehicle={handleNewVehicle}/>}
        </div>
    )
}
const ProfileTargets = () => {
    return (
        <div className="flex flex-col col-span-full">
            <div>
                Target Settings
            </div>
            <select>
                <option>Master Goal</option>
                <option>Multiple-Goals</option>
            </select>
            <h3 className="text-xl">Your goal</h3>
            <div className="bg-white w-full">
                
                <form className="w-full">
                    <span className="text-xl">Master Goal </span><input type="button" value={"name goal"}/>
                    <span className="flex relative w-full">
                    <label>Type:</label>
                    <select className="w-full">
                        <option className="text-center">Portfolio value</option>
                        <option className="text-center">Paper goal</option>
                    </select>
                    </span>
                    <span className="flex relative w-full">
                    <label>Amount:</label><input type="text" placeholder="100" className="text-center flex-1 w-full"/>
                    </span>
                </form>
            </div>
            <h3 className="text-xl">Your goals</h3>
            <div className="bg-white">
                
                <ul>
                    <span className="text-xl">Goal 1 </span><input type="button" value={"add goal"}/>
                    <li>target1 <input type="button" value={"edit"}/> <input type="button" value={"x"}/></li>
                    <span className="text-xl">No goal</span>
                </ul>
            </div>
            <input type="button" value={"Add new"}/>
            {/* <CreateNewTarget/> */}
        </div>
    )
}

const Profile = () => {
    const { currentUser, logout } = useAuth();
    const {state:{profile:{investment_schedule:setting}}}=useContext(ShayveState)
    const fetchUrl = 'https://coinbaseoauthredirect-pbdntqnsna-uc.a.run.app';
    const [error, setError] = useState('');
    const [popup,dispatch] = useReducer(ProfileReducer,null);
    const [whyPopup,showWhyPopup] = useState(false)
    const {saveEnabled} = useContext(SettingsContext)

    function toggleWhyPopup(){
        saveEnabled.set(false)
        showWhyPopup(!whyPopup)
    }

    async function showPopup(e){
        if(popup===e.target.value) dispatch({type:null})
        else {
            dispatch({type: e.target.value});
        }
    }


    const openOAuthPopup = (url, w, h) => {
        const left = window.innerWidth / 2 - w / 2;
        const top = window.innerHeight / 2 - h / 2;
        
        const popup = window.open(
          url,
          '_blank',
          `toolbar=no, location=no, directories=no, status=no, menubar=no, scrollbars=no, resizable=no, copyhistory=no, width=${w}, height=${h}, top=${top}, left=${left}`
        );
        // Listen for changes in the popup's URL.
        const popupChecker = setInterval(() => {
            try {
            if (popup.location.href.endsWith('/?authorized=true')) {
                clearInterval(popupChecker); // Stop checking the popup URL.
                popup.close(); // Close the popup window.
                window.location.reload();
            }
            } catch (error) {
            // Handle exceptions that occur when the popup is on a different domain.
            }
        }, 100);
        
        return popup;
      };
      //"Log in with Coinbase"
    const handleOAuthLogin = () => {
        openOAuthPopup(fetchUrl, 400, 600);
    };

    //Log Out
    async function handleLogout(){
        setError('')

        try {
            await logout()
            window.location.reload();
        } catch (error) {
            setError('Failed to log out.')
            console.error(error)
        }
    }
    return (
        <>
        {currentUser ? 
        <section id="app-body" className="bg-[buttonface] flex-grow border-x-2 text-center">
{/*             <div className="mr-[1em] right-0 mt-[1em] absolute w-[42px] h-[42px] flex items-center justify-center">
            <SVG src={'sms.svg'}/>
            </div> */}
        <div className="grid-bois">
        <div id="profile" className="flex flex-col h-full justify-between w-full">
            <h1 className="mt-[2em] text-bold text-[1.4em] select-none">Profile</h1>
            {/* <div className="ml-[3em] mt-[5em] absolute w-[42px] h-[42px] border-slate-300 border-1 flex items-center justify-center">
                <SVG src={'camera.svg'}/>
            </div> */}
{/*             <input type="text" placeholder="username"/>
            <input type="text" placeholder="display name"/>
            <input type="text" placeholder="bio (why you sayve)"/> */}
            {/* <h1 className="mt-[.75em] text-bold text-[1.4em]">Settings</h1> */}
            <section className="grid grid-cols-3 gap-[1.5em] mt-[1em] justify-center place-items-center">
            <span className="col-span-full text-slate-300 flex flex-col items-center"><button className="flex col-span-3 text-slate-300 select-none" disabled>App Settings</button><small>(under development)</small></span><hr className="w-[50%] col-span-3 text-slate-300"/>
                <div className="flex col-span-3"><button className="select-none" value={ProfilePopups.INVESTMENT_PREFS} onClick={showPopup}>Investing</button>
                {popup===ProfilePopups.INVESTMENT_PREFS?<ChevronUpIcon className="w-[18px] ml-3 mt-1" height={18}/>:<PlusSmallIcon className="w-[18px] ml-3 mt-1" height={18}/>}</div>
                    {popup === ProfilePopups.INVESTMENT_PREFS && <ProfileSettings setting={setting}/>}<hr className="w-[50%] col-span-3 text-slate-300"/>
                    <span className="col-span-full text-slate-300 flex flex-col items-center"><button className="flex col-span-3 text-slate-300 select-none" value={ProfilePopups.VEHICLE_PREFS} onClick={showPopup} disabled>Mileage Settings</button><small>(under development)</small></span>
                    {popup === ProfilePopups.VEHICLE_PREFS && <ProfileVehicles/>}<hr className="w-[50%] col-span-3 text-slate-300"/>
                    <span className="col-span-full text-slate-300 flex flex-col items-center"><button className="flex col-span-3 text-slate-300 select-none" value={ProfilePopups.TARGET_PREFS} onClick={showPopup} disabled>Targets Settings</button><small>(under development)</small></span>
                    {popup === ProfilePopups.TARGET_PREFS && <ProfileTargets/>}<hr className="w-[50%] col-span-3 text-slate-300"/>
                <span className="col-span-full text-slate-300 flex flex-col items-center"><button className="flex col-span-3 text-slate-300 select-none" disabled>Dark/Light Mode</button><small>(under development)</small></span>
            </section>

            <span className=" text-2xl my-[2em] select-none">Fund Shayve</span>

            <button onClick={handleLogout} className="mb-2 underline select-none">log out</button>
            </div>       
            </div>
            </section>
            :
            <section id="app-body" className="bg-[buttonface] flex-grow border-x-2 text-center">
            <div className="grid-bois">
            <div id="no-profile" className="w-full flex flex-col">
                <section className="flex flex-col w-full justify-between items-center mb-2">
                <h1 className="text-[1.5em] w-[60%] mb-4">Log in with Coinbase</h1>
                <h2>Don't have a Coinbase account?</h2>
                    <button onClick={handleOAuthLogin} className="bg-[#ffae00] font-bold text-[1.75em] py-3 w-[50%] sm:w-[40%] rounded-3xl mt-4 text-white ring-[1.5px] ring-white ring-opacity-20">Sign Up</button>
                </section>
                <hr className="w-[60%] place-self-center mt-2 mb-2"/>
                <section className="flex flex-col justify-between w-full items-center mt-2">
                    <h2 className="text-sm w-[60%] mt-1">Or if you do,</h2>
                    <button onClick={handleOAuthLogin} className=" bg-[#4b75ff] disabled:bg-gray-500 text-white rounded-xl py-2.5 px-2 w-[50%] sm:w-[40%] mt-2">Log in via Coinbase</button>
            </section>
            <a className={`text-sm underline mt-4 ${whyPopup&&"mb-2"} cursor-pointer`} onClick={toggleWhyPopup}>{whyPopup? <><u className='text-sm h-full inline-block'>Why Coinbase?</u> (close &times;)</>:"Why Coinbase?"}</a>
            
            {whyPopup&&<><div className="flex flex-col items-center">
                Shayve doesn't use accounts of its own. Instead, it allows you to invest automatically into your existing Coinbase account whenever you sayve money. Shayve will also display the gains/losses from that.
                <span className="text-sm w-[60%] mt-2 italic font-light">Log in via coinbase to start investing with Shayve. And also to access your progress on multiple devices.</span>
            </div>
            </>}

        </div>
        </div>
        </section>
        }
        </>

        //Permissions
        //<location><notifications>

        //Log out
    );
}

export default Profile;