import * as crypto from 'crypto-browserify'
//Ok, so on a side note. maybe I could archive invested and other archived inputs. Like,into a separate array.
//Same with non-investables. (less important though)
//That way I'm only checkingForCharges on relevant array of inputs

//Ok and eventually, I shall handle partial fills and failed_orders more gracefully
//as well as insufficient fund labeling, and retrials

//for server
//if a target is_filled 0 = false , 1 = true, everything else in between = partial value
    //server should do weighted checks and cuts for insufficient funds cases, or partial fill
//if a target is_in_order.uuid = null (it doesn't have a uuid)
//if a target is_in_order.platform = coinbase or paca or something!
//if a target is_in_order.weight = percent of that order, the input represents
// target.assets_purchased = amt of each stock,crypto or asset you bought with savings
// target filled_date = array of dates and percentage that was filled on date (for each order)
export const BUILD_CHARGES_ERRORS = {
    INSUFFICIENT_INPUTS: "The sum of inputs to invest are < the user's charge_threshold: ",
    NO_INPUTS: "There currently are no inputs to invest.",
    NO_NEW_INPUTS_TO_INVEST: "There are no new inputs to invest."
}


//first, check all of state's inputs for charge_threshold attainment
    //firstly, lets check for new charges
const getInputsToInvest = async (state_dot_inputs) =>{
    const inputs = state_dot_inputs
    // console.log('INPUTS:', inputs)
    //first of all filter inputs for inputs where times_invested < should_invest.count
    let inputsToInvest = inputs.filter(input=>
        {return input.times_invested < input.should_invest.count|| input.targets.some((target)=>target?.hash)}
    )
    // const inputTargetsToServerCheck = []
    // inputsToInvest.forEach(input=>{ //define should check list
    //     input.targets.forEach((target, index) => {
    //         if (target.is_in_orders === undefined || target.is_in_orders.percent_filled < 1) {
    //           inputTargetsToServerCheck.push({ input: input.inp_id, targetIndex: index });
    //         }
    //       });
    // })
    
    inputsToInvest = inputsToInvest.filter((input)=>{ //refine should invest list
        return input.targets.some(target=> {
            return target?.is_in_orders?.percent_placed < 1 || target?.is_in_orders?.percent_placed === undefined
        })
    })
    if (inputsToInvest.length > 0) {
    return inputsToInvest
    } else {
    return [] //No new inputs
    }
}

//secondly, the bread and butter of this function, construct the charge objects
        //which contain the document updates data for server.
        
//will need to create a HASH for the charge_group, so that the charge does not get duplicated
//well, actually for EACH document that's being updated, based on the WAY it's being updated
//well actually for EACH target_child in the document that's being included. 
    //Because one target object of an input could be executed again, after another target object is
    //added successfully to the same document, if server perceives the document hash is different
    //server will check if hash is not included in the pending charges array. and pop hash once updates
    //have been successfully made to the document.
        //well, actually, hash can just be saved to state locally (and targets whose hash exists can be rejected) 
            //to prevent double charges
        //and hashes to pop. (charge was successfully executed)
    //if any of the target hashes match ___ target hash array, reject the charge asset (until next iteration of the function)
const getChargeData = async (state) => {
    state.charges.inputs = []
    const prevCharges = state?.charges?.sums ?? []
    const chargeThresh = state?.profile?.investment_schedule?.charge_min
    const inputsToInvest = await getInputsToInvest(state.inputs)

    //re-sum the charges based on current inputs
    let chargesByAsset = prevCharges.map(charge=>{
        const {asset,platform} = charge
        charge.inputs = [] //reset it each time because it's going to re-scan existing inputs 
        let assetBabies = []
        inputsToInvest.forEach((input)=>{
            let targetIndexes=[]
            if (input.targets.some((target)=>{
                return target.asset === asset && target.platform === platform
            })){ //input can have two different targets going to the same asset!
            input.targets.forEach((target, index)=>{
                if(target.asset === asset && target.platform === platform && target?.hash){
                    const stripped_input = {
                        amt: input.amt,
                        inp_id: input.inp_id,
                        times_invested: input.times_invested,
                        targets: input.targets
                    }
                    state.charges.inputs.push(stripped_input)
                    targetIndexes.push(index)
                }
            })
            targetIndexes.forEach((num)=>{
                assetBabies.push(input.targets[num].to_invest)
            })
            }
        })
        const currentSum = assetBabies.reduce((total,inputsum)=>{
            return total + inputsum
        },0)
        charge.sum = currentSum
        return charge
    })
    .filter((charge)=>{
        return charge.sum>0
    })

    // console.log(chargesByAsset)

    let inputsShouldCalculate = inputsToInvest.filter((inputs)=>{ //particularly want the ones with no hash & needs placing (NEW)
        return inputs.targets.some((targetChild)=>{
            return (targetChild?.is_in_orders?.percent_placed < 1 || targetChild?.is_in_orders?.percent_placed === undefined) && !targetChild?.hash
        })
    })
    if (inputsShouldCalculate.length === 0 ) throw new Error(BUILD_CHARGES_ERRORS.NO_NEW_INPUTS_TO_INVEST)

    inputsShouldCalculate = inputsShouldCalculate.map((input)=>{//filter the remaining's CHILDREN to just what will invest
        const filteredInput = {
            ...input,
            targets: input.targets.filter((target)=>{
                if(!target?.is_filled && target?.is_in_orders ? target.is_in_orders.percent_placed < 1 : target?.is_in_orders?.percent_placed === undefined ){ //idk... refine. but def get rid of filled targets
                    return true
                } else return false
            })
        }
        return filteredInput
    })

    let inputTargetArray = []
    inputsShouldCalculate.forEach((input,inputIndex)=>{ //lay out the inputtargets.
        inputsShouldCalculate[inputIndex].targets = input.targets
        .forEach((inputTarget)=>{
            inputTargetArray.push(inputTarget)
        })
    })
    //now we want to get the asset-input Pairs each = {platform,pair,charge}
        //for each target get the asset associated to the targetId
        inputTargetArray.forEach((targetChild)=>{
            const {platform, asset} = targetChild
            const assetPair = {platform,asset}

            if (chargesByAsset.some((pair)=>{
                return pair.platform === assetPair.platform && pair.asset === assetPair.asset
            })){
                // console.log(`asset-pair exists.`)
            } else {
                chargesByAsset.push(assetPair)
            }
        })

    chargesByAsset.forEach((pair,pairIndex)=>{
        let targetInputsForCharge = [] //built fresh on each iteration :)
        const chargeAsset = pair.asset;
        const chargePlatform = pair.platform;
        //Now we want to take every single targetInput of the same asset and sum them
        chargesByAsset[pairIndex].inputs = chargesByAsset[pairIndex]?.inputs ? chargesByAsset[pairIndex].inputs : []
        inputTargetArray.forEach(inputTarget=>{
            const {asset:inputAsset, platform:inputPlatform} = inputTarget
            if(inputAsset === chargeAsset && inputPlatform === chargePlatform) {
                targetInputsForCharge.push(inputTarget)
                chargesByAsset[pairIndex].inputs.push(inputTarget)
            }
        })

        if(!pair?.sum){
            const pairSum = targetInputsForCharge.reduce((total,inputTarget)=>{
                return total + inputTarget.to_invest;
            },0);
            chargesByAsset[pairIndex].sum = parseFloat(pairSum.toFixed(2));
        } else {
            const pairSum = pair.sum + targetInputsForCharge.reduce((total,inputTarget)=>{
                return total + inputTarget.to_invest;
            },0);
            chargesByAsset[pairIndex].sum = parseFloat(pairSum.toFixed(2));
        }
    })
    chargesByAsset.sort((a,b)=>{
        return b.sum - a.sum
    }) // there we have an assorted assetSums array!

    let assetTargetPairs_above_thresh = [] //instead of filtering the chargesByAsset (keep em all) and just document those above thresh here
    chargesByAsset.forEach((pair,pairIndex)=>{
        const pairSum = pair.sum
        if (pairSum >= chargeThresh){
            assetTargetPairs_above_thresh.push(pairIndex)
        }
    })//the qualifying charges indexes have been documented in assets..._above_thresh array
    // console.log(assetTargetPairs_above_thresh)

    //set the hashes for all stateInputs 
    //only push flagged charge_hashes to a state-level array (recurFails, or firestoreUnsure)
    //otherwise keep everything within state.inputs

    inputTargetArray.forEach((targetChild, targetChildIndex)=>{
        const idString = targetChild.for_target_id + targetChild.for_input_id
        const hash = crypto.createHash('sha256')
        .update(idString)
        .digest('hex')
        targetChild.hash = hash
        inputTargetArray[targetChildIndex] = targetChild
        state.inputs = state.inputs
        .map((input)=>{
            if(input?.inp_id && input?.inp_id === targetChild.for_input_id){
                let targetIndexInState
                input.targets.some((inputTarget,index)=>{
                    if (inputTarget.for_target_id === targetChild.for_target_id){
                        targetIndexInState = index
                        return true
                    } else return false
                })
                input.targets[targetIndexInState] = targetChild
                if(!state.charges.inputs.some((prevInp)=>{
                    return prevInp.inp_id === input.inp_id
                })){
                const stripped_input = {
                    amt: input.amt,
                    inp_id: input.inp_id,
                    times_invested: input.times_invested,
                    targets: input.targets
                }
                state.charges.inputs.push(stripped_input)
                }
                return input
            } else return input
        })
    })



    const updatedChargeSums = chargesByAsset
    const chargesAboveThresh = assetTargetPairs_above_thresh
    state.charges.sums = updatedChargeSums
    const updatedState = state

    // console.log(state)

    if(updatedChargeSums.length > 0 || chargesAboveThresh.length > 0) {
        return {updatedState, chargesAboveThresh}; //instead of returning these we want it to update these with the new!
    }
    else throw new Error(BUILD_CHARGES_ERRORS.INSUFFICIENT_INPUTS+`${chargeThresh}`); // will jump to catch-clause
}

/**
 * Create a charge object that can be gracefully handled out on the server, via async HTTP
 * //returns {inputsShouldInvest, chargesByAsset} to the server, where chargesByAsset are ASSETS whose targetChildren toinvest sum exceeds a user's charge_threshold/charge_min
        //inputsShouldInvest, so the server can update the inputs for firestore, 
        //and chargesByAsset for the actual calculations
    //calculate how much to invest (for each PLATFORM/ASSET) 
    //(each target's fill remainder * to_invest amount)
 * */
const buildCharges = async (passedState) => {
    // try {
        const result/* {chargesByAsset:charge_data, charge_hashes} */ = await getChargeData(passedState)
        return result/* {inputsToInvest,charge_data,charge_hashes} */
    // } catch (error) {
    //     console.error(error)
    //     return null
    // }
};

export default buildCharges;