// import { POPUPS } from "../SCRIPTS/PopupReducer";
// import { ShayveState } from "../../Providers/ShayveContext";
// import { useContext } from "react";

// const {navHome,navInputs,navProfile,createInput} = POPUPS

const AppHeader = () => {
  // const {popups:{current:Page}} = useContext(ShayveState);

  return (
    <section
      id="app-head"
      className=" h-fit w-full bg-[buttonface] flex justify-center border-t-2 border-x-2 py-[1.25rem]"
    >
      {/* {!currentUser ? <><button onClick={() => navigate('/login')}>Login</button>
        </> :<button onClick={() => navigate('/profile')}>Profile</button>} */}
      {/* <button onClick={currentUser?()=>setPopup(popups.current !== POPUPS.EnterApiKey ? POPUPS.EnterApiKey:null):null} disabled={!currentUser}>{currentUser?'cbase apiKey':'log in to enter api key'}</button> */}
      {/* <button onClick={() => loadState(state.settings.userId)}>Load latest State</button> */}
      <h1 className=" text-4xl"style={{ textAlign: "center" }}>{'Shayve Finance (🇺🇸)'}</h1>
      {/* <button onClick={() => updateSettings(state, 'stateGasPrice', 4.16)}>Update Settings</button> */}
      {/* <button onClick={() => updateLocalState(state, 'targets', targetDefault)}>Update targets</button> */}
      {/* <button onClick={() => addVehicle(state)}>add (default) Vehicle</button> */}
    </section>
  );
};

export default AppHeader;
