import React, { useContext } from 'react';
import { ShayveState } from '../Providers/ShayveContext';
import Input from './Components/InputComps/ViewInput';
import './CSS/shaveStyles.css'
import { ReactSVG as SVG } from 'react-svg';

const Inputs = () => {
    let { inputs } = useContext(ShayveState)?.state;
    if (!inputs) inputs = []

    return (
        <section id="app-body" className="bg-[buttonface] flex-grow border-x-2 text-center">
        <div className="grid-bois">
        <div className='hist-bois'>
            <div className='mb-3 flex justify-center relative items-center'>
            <h3 className='hist text-xl w-full'>Your History</h3>
                <SVG src='chart-square.svg' className='absolute right-0 mr-5'/>
            </div>
            <ul className='list'>
                <div id='table-header' className='gli'>
                    <span>#</span>
                    <span>date</span>
                    <span>$/🚗</span>
                    <span>desc</span>
                    <span>invested</span>
                    <span></span>
                </div>
                {inputs.filter((input)=>{
                    return !input.FETCH;
                }).map((input, i, inputs) => {
                    const l = inputs.length
                    const itemNumber = i+1;
                    return (
                        <span key={input.inp_id} className='numbered-cli'>
                        <div className='gli' key={itemNumber}>
                            {itemNumber}
                        </div>
                        <Input input={input}/>
                        </span>
                    );
                })}
                {/* {inputs.map(input => (<Input key={input.inp_id} input={input}/>))} */}
            </ul>
            <div className='hist-headerfooter'>
                <span className='hist-overlap-container text-gray-500'>
                    load more 🔄
                </span>
            </div>
        </div>
        </div>
        </section>
    );
}

export default Inputs;