import {Listbox,Transition} from '@headlessui/react'
import { CheckIcon, ChevronUpDownIcon } from '@heroicons/react/20/solid'
import { Fragment, useContext, useEffect, useState } from 'react'
import { Cases } from '../../Profile'
import { SettingsContext } from '../../../Providers/SettingsContext'

const SelectOption = ({options,selectedValue,setSelectedValue,useWholeOption,caseType,loadPaymentOptions,optionsLoaded}) => {
    const { initSelectedValue,
        initSelectedAsset,
        initSPM } = useContext(SettingsContext)
    const isFunding = caseType===Cases.FUNDING_SELECTION

    let initValue
    //use case types
    try {
        switch(caseType){
            case Cases.ASSET_SELECTION:
                initValue = initSelectedAsset
                break
            case Cases.FUNDING_SELECTION:
                initValue = initSPM
                break
            default:
                initValue = initSelectedValue
        }
    } catch (error) {
        console.error('Error:', error)
    }

    const isInitValue = (option,current=false) => {
        if(current){
            if(caseType===Cases.INVESTMENT_SCHEDULE)
            return option.value===selectedValue
            else if(caseType === Cases.ASSET_SELECTION) {
            return option.value === selectedValue
            }
            else if (caseType === Cases.FUNDING_SELECTION) {
                return option?.value === selectedValue.id || option.type === "fiat_account" && options.some(option=>option?.value === "NONE_FOUND")
            }
        } else {
            if(caseType===Cases.INVESTMENT_SCHEDULE)
            return option.value===initValue
            else if(caseType === Cases.ASSET_SELECTION) {
            return option.value === initValue
            }
            else if (caseType === Cases.FUNDING_SELECTION) {
                return option?.value === initValue || option.type === "fiat_account" && options.some(option=>option?.value === "NONE_FOUND")
            }
        }
    }
    const clickButton = () =>{
        if(!optionsLoaded && isFunding)
        loadPaymentOptions()
    }

    useEffect(()=>{
        if(optionsLoaded && isFunding){
        setSelected(options[options.findIndex((option)=>isInitValue(option,true))]??initSPM)
        // console.log(options,selectedValue)
        }
    },[options,optionsLoaded])
    // options.forEach(option=>console.log(option.value))
    // console.log(initValue)

    const [selected, setSelected] = useState(options[(options.findIndex((option)=>isInitValue(option)))]??options[(options.findIndex(()=>true))])
    const selectionChanged = false //remove (I think)
    
    const handleDropdownChange = (event,option) => {
        setSelectedValue(useWholeOption?option:option.value);
    };
    return (
        <div className="w-72">
            <Listbox value={selected} onChange={setSelected}>
                    <div className="relative mt-1">
                    <Listbox.Button onClick={clickButton} className="relative w-full cursor-default rounded-lg bg-white py-2 pl-3 pr-10 text-left shadow-md focus:outline-none focus-visible:border-indigo-500 focus-visible:ring-2 focus-visible:ring-white/75 focus-visible:ring-offset-2 focus-visible:ring-offset-orange-300 sm:text-sm">
                        <span className={`block truncate ${selectionChanged?'text-gray-400':''}`}>{selected.label}</span>
                        <span className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-2">
                        <ChevronUpDownIcon
                            className="h-5 w-5 text-gray-400"
                            aria-hidden="true"
                        />
                        </span>
                    </Listbox.Button>
                    <Transition
                        as={Fragment}
                        leave="transition ease-in duration-100"
                        leaveFrom="opacity-100"
                        leaveTo="opacity-0"
                    >
                        <Listbox.Options className="absolute mt-1 max-h-60 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black/5 focus:outline-none sm:text-sm text-start z-10">
                        {options.map((option, optionIdx) => (
                            option.isDivider? 
                            <div key={optionIdx} className='select-none py-2 pl-4 pr-4 text-sm text-gray-500'>
                                {option.label}
                            </div>
                            :
                            <Listbox.Option
                            key={optionIdx}
                            disabled={option.isDivider||option.unavailable}
                            className={
                                `relative cursor-default select-none py-2 pl-10 pr-4 text-start ${
                                    option.value===selected.value? 'bg-amber-100 text-amber-900' : 'text-gray-900'
                                }`
                            }
                            value={option}
                            onClick={(event)=>handleDropdownChange(event,option)}
                            >
                            {
                                <>
                                <span
                                    className={`block truncate ${
                                    option.value===selected.value ? 'font-medium' : 'font-normal'
                                    }`}
                                >
                                    {option.label}
                                </span>
                                {option.value===selected.value ? (
                                    <span className="absolute inset-y-0 left-0 flex items-center pl-3 text-amber-600">
                                    <CheckIcon className="h-5 w-5 select-none" aria-hidden="true" />
                                    </span>
                                ) : null}
                                </>
                            }
                            </Listbox.Option>
                        ))}
                        </Listbox.Options>
                    </Transition>
                    </div>
                </Listbox>
        </div>
    );
}

export default SelectOption;