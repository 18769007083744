import React, { useContext, useEffect, useState, useRef } from 'react';
import { ShayveState } from '../../../Providers/ShayveContext';
export const updateLocalState = (state, key, value) => {
    const newState = {
        ...state,
        [key]: value
    }
    console.log(newState)
    localStorage.setItem('shavings', JSON.stringify(newState))
    return newState
}

let every = ['month', 1, 12] //unit, interval, timesToRepeat

const EditInputPopup = ({shaving, shavingId, setToggleEditShaving}) => {
    const { state, pushState, shavings } = useContext(ShayveState)

    const [isChecked, setIsChecked] = useState(false)
    const [fromInputValue, setFromInputValue] = useState(shaving.from.toString().replace(/,/g, '#'))
    const [dateInputValue, setDateInputValue] = useState('')

    const dateSelectorRef = useRef(null)
    const fromInputRef = useRef(null)

    
    useEffect(() => {
        let currentShavingRecurState
        if(shaving){
        currentShavingRecurState = shavings.find(shaving => shaving.shavingid === shavingId).recursEvery
        }
        if (currentShavingRecurState) {
            console.log(`it recurs every...`)
            setIsChecked(true)
        }
    })

    function setRecurring(shavingId, timeframeArray = every) {
    const recurringShaving = shavings.find(shaving => shaving.shavingid === shavingId)
    recurringShaving.recursEvery = timeframeArray;
    return recurringShaving;
    }
    const handleFromChange = (e) => {
        setFromInputValue(e.target.value);
    }

    const handleDateChange = (e) => {
        setDateInputValue(e.target.value)
    }

    const handleRecurringToggle = () => {
        let updatedShaving
        setIsChecked(prevState => !prevState) // for the next go-around
        if(!isChecked) {
            //use popup for more details.. defer next two lines for popup form submit
            updatedShaving = setRecurring(shavingId, every)
        }
        else {
            updatedShaving = setRecurring(shavingId, null)
        }
        const updatedShavingsList = [
            ...state.shavings.filter(shaving => shaving.shavingid !== updatedShaving.shavingid),
            updatedShaving
        ]
        const newState = updateLocalState(state, 'shavings', updatedShavingsList);
        pushState(newState)
        //post request to server... || trigger useEffect post request in GlobalState.
    }

    const fromInputValueArray = (function splitStringByHashtag(inputString) {
        let fromFirstPart = null;
        let fromSecondPart = null;
      
        if (inputString.includes('#')) {
          const parts = inputString.split('#');
          fromFirstPart = parts[0];
          fromSecondPart = parts[1];
        } else {
          fromFirstPart = inputString;
        }
      
        return [ fromFirstPart, fromSecondPart ];
      })(fromInputValue);

    const handleSubmit = (e) => {
        e.preventDefault();
        let timeframeArray=[]
        if(isChecked) {
            //use popup for more details.. defer next two lines for popup form submit
            timeframeArray = every
        }
        else {
            timeframeArray = null
        }
        let newDate = dateInputValue !== ''
        if(newDate) {
        newDate = dateSelectorRef.current.value
        } else {
        newDate = shaving.dateCreated
        }
        

        let updatedShaving = {
            ...shaving,
            dateCreated: newDate,
            from: fromInputValueArray,
            recursEvery: timeframeArray
        }
        const newState = {
            ...state,
            shavings: [
                ...state.shavings.filter(shaving => shaving.shavingid !== updatedShaving.shavingid),
                updatedShaving
            ]
        }
        setFromInputValue('')
        setDateInputValue('')
        pushState(newState)
        setToggleEditShaving(false)
        console.log(newState)
    }

    

    return (
        <form onSubmit={handleSubmit}>
            <label htmlFor='update-inp-from'>Description: </label>
            <input ref={fromInputRef} value={fromInputValue} autoFocus onFocus={(e) => e.target.select()} onChange={handleFromChange} id='update-inp-from' type='text'/>
            
            <label htmlFor='date-selector'>Date: </label>
            <input ref={dateSelectorRef} value={dateInputValue} onChange={handleDateChange} id='date-selector' type='date'/>

            <input id='recurring-check' type='checkbox' checked={isChecked} onChange={handleRecurringToggle}/>
            <label htmlFor='recurring-check'></label>

            <input type='submit' value={'submit'}/>
        </form>
    );
}

export default EditInputPopup;