import React, { createContext, useContext, useEffect, useState } from 'react';
import { ShayveState } from './ShayveContext';
import { useAuth } from './AuthContext';
import { db } from '../firebase';
import { setDoc, doc } from 'firebase/firestore';
import { ProfilePopups } from '../Pages/Profile';
import BabyShayveState from '../Pages/Components/Vars/shayve_classes';
import { DropdownContext } from './AlertContext';
export const SettingsContext = createContext(null)


export const SettingsProvider = ({children}) => {
    const {currentUser} = useAuth()
    const {state:{profile:{investment_schedule}},state,saveState}=useContext(ShayveState)
    const {setAlert} = useContext(DropdownContext)
    let setting = investment_schedule
    let {profile} = state
    if (profile?.investment_schedule === undefined){
        const state = new BabyShayveState(currentUser?.uid??null)
        saveState(state)
        setting = state.profile.investment_schedule
        profile = state.profile
    }
    const initSelectedValue = setting.charge_date
    const initChargeMin = setting.charge_min
    const initChargeMax = setting.charge_max
    const initSelectedAsset = profile.targets.user[0].asset.value
    const initSPM = profile.settings.funding_src

    const [scheduleValue, setScheduleValue] = useState(initSelectedValue);
    const [selectedAsset,setSelectedAsset] = useState(initSelectedAsset);
    const [selectedPaymentMethod,setSelectedPaymentMethod] = useState(initSPM)
    const [chargeMin,setChargeMin] = useState('');
    const [chargeMax,setChargeMax] = useState('');
    const [saveEnabled, setSaveEnabled] = useState(false)

    const checkEnableSave = () => {
        if((
            (chargeMin!==initChargeMin &&chargeMin!=='') || (chargeMax!==initChargeMax &&chargeMax!=='')||
            scheduleValue !==initSelectedValue || selectedAsset!==initSelectedAsset|| selectedPaymentMethod !==initSPM
            ))
        setSaveEnabled((chargeMin!==initChargeMin &&chargeMin!=='')||(chargeMax!==initChargeMax &&chargeMax!=='')||scheduleValue !==initSelectedValue || selectedAsset!==initSelectedAsset|| (selectedPaymentMethod?.id ?? selectedPaymentMethod?.value) !==initSPM.id)
      }
    useEffect(()=>{
        checkEnableSave()
    },[selectedAsset,selectedPaymentMethod,scheduleValue,chargeMin,chargeMax])

    function saveSettings(popup){
        // console.log(initSPM,selectedPaymentMethod)
        if(!currentUser)return
        if(popup===ProfilePopups.INVESTMENT_PREFS){
            const fieldsToMerge = ['profile.investment_schedule.charge_min','profile.investment_schedule.charge_max','profile.investment_schedule.charge_date']
            let charge_min = chargeMin !=='' ? chargeMin : initChargeMin
            let charge_max = chargeMax !=='' ? chargeMax : initChargeMax
            let charge_date
            let day = null

            let user = [
                {...profile.targets.user[0],
                asset: {
                    exchange:"COINBASE",                
                    invest_from:'DEFAULT',
                    value:selectedAsset
                }
                }
            ] //build this out...
            const {isDivider,unavailable,value,...funding_src} = selectedPaymentMethod
            funding_src.id = funding_src?.id? funding_src.id : value
    
            if(scheduleValue === "IMMEDIATELY")
            charge_date = "IMMEDIATELY"
            else {charge_date = "DOTW";day=scheduleValue;fieldsToMerge.push('profile.investment_schedule.dotw')}
            if(/* selectedAsset !== "ADA-USD" || */ selectedAsset !== initSelectedAsset) fieldsToMerge.push('profile.targets.user') //will need to do some array union magic
            if(selectedPaymentMethod.id !== initSPM.id) fieldsToMerge.push('profile.settings.funding_src')
            let dotw = {
                day,
                time: 0
            }
            try {
                const docId = currentUser.uid
                const stateDocRef = doc(db,`users/${docId}/shayve/state`)
                setDoc(stateDocRef,{
                    profile:{investment_schedule:{
                            charge_date,
                            charge_min,
                            charge_max,
                            dotw
                        },
                        settings: {
                            funding_src
                        },
                        targets: {
                            user
                        }
                    }
                },{mergeFields:fieldsToMerge})
                .then(()=>{
                    setAlert({value:true,message:"Settings saved successfully.",type:"alert-success"})
                })
            } catch (error) {
                setAlert({value:true,message:"Failed to save settings: "+error.message,type:"alert-error"})
            }
            //Save changed settings locally!. Refresh the chargeMin & Max values on screen!
        }
    }

    const values = {
        chargeMin:{current:chargeMin,set:setChargeMin},
        chargeMax:{current:chargeMax, set:setChargeMax},
        schedule:{current:scheduleValue,set:setScheduleValue},
        selectedAsset:{current:selectedAsset,set:setSelectedAsset},
        selectedPaymentMethod:{current:selectedPaymentMethod,set:setSelectedPaymentMethod},
        saveEnabled:{current:saveEnabled,set:setSaveEnabled},
        initSelectedValue,
        initChargeMin,
        initChargeMax,
        initSelectedAsset,
        initSPM,
        checkEnableSave,
        saveSettings
    }
      
    return (
        <SettingsContext.Provider value={values}>
            {children}
        </SettingsContext.Provider>
    );
}

export default SettingsProvider;