import React, { useState, useContext, useRef } from 'react';
import NewShayveInput from '../Vars/input_args';
import { POPUPS } from '../../SCRIPTS/PopupReducer';
import { ShayveState } from '../../../Providers/ShayveContext';
import { ShayveInputBundle, VALUE } from '../Vars/shayve_classes';
const {_, ShayveInput} = ShayveInputBundle
const InputForm = () => {
    const inputRef = useRef(null)
    const popInputRef = useRef(null)

    let {inputs} = useContext(ShayveState)?.state;
    if (!inputs) inputs = []



    const moneyAmounts = inputs
    .filter(input => input.inp_type?.saved_type === VALUE.MONEY)
    .map(input => input?.amt?.total)
    const moneyTotal = moneyAmounts.reduce((acc, item) => (acc += item), 0)

    const gasAmounts = inputs
    .filter(input => input.inp_type?.saved_type === VALUE.MILES)
    .map(({ amt, gas_price, car_selected: { mpg } }) => ({ amt, gas_price, car_selected: {mpg} }))
    const gasTotal = gasAmounts.reduce((total, { amt: miles, gas_price, car_selected: {mpg} }) => (total += miles?.total / mpg * gas_price), 0);

    const total= Math.floor(100*(moneyTotal+gasTotal))/100;

    //Create Input
    const [inputType, setInputType] = useState(VALUE.MONEY)
    const [formHasValue, setFormHasValue] = useState(false);
    const [isInputFocused, setInputFocused] = useState(false)
    const [inputValue, setInputValue] = useState('')
    const { add, state, popups } = useContext(ShayveState)


    const handleInputFocus = () => {
        setInputFocused(true);
        setInterval(()=>{        if (popInputRef.current) {
            popInputRef.current.focus();
          }},50)
      };

    function togglePopup(argument){
        popups.set(argument)
    }

    function toggleInputType(e) {
        // if(inputType===VALUE.MONEY)setInputType(VALUE.MILES)
        // else setInputType(VALUE.MONEY)
        if (popInputRef.current) {
            popInputRef.current.focus();
          }
    }

    const handleInputChange = (event) => {
        let roundedNum
        let cleanedInput
        if (event.target.value === '.') cleanedInput = '';
        else cleanedInput = event.target.value.replace(/[^0-9.]+/g, '').replace(/(\..*)\./g, '$1')
        if(cleanedInput.includes('.')) {
            if(inputType===VALUE.MONEY)
            {            
        if(cleanedInput.length > 12) 
            cleanedInput = cleanedInput.slice(0,12)}
            else {
                if(cleanedInput.length > 12)
                cleanedInput = cleanedInput.slice(0,12)}
        } else cleanedInput = cleanedInput.slice(0,10)
        const decIndex = cleanedInput.indexOf('.') + 1
        if (inputType === VALUE.MONEY) {
            if(decIndex) {
                if(cleanedInput.slice(decIndex) < 3)
                roundedNum = Math.abs(Math.floor(Math.round((cleanedInput*100)))/100);
                else roundedNum = Math.abs(Math.floor(Math.round(Math.floor((cleanedInput.slice(0,cleanedInput.length))*100)))/100)
                if (roundedNum.toString().length > 12)roundedNum = parseFloat(roundedNum.toString().slice(0,12))
            } else roundedNum = Math.abs(Math.floor(Math.round((cleanedInput*100)))/100)
            if (roundedNum.toString().length > 10)roundedNum = parseFloat(roundedNum.toString().slice(0,10))
        }
        else {
            if(decIndex) {
                if (cleanedInput.slice(decIndex) < 2) {
                    roundedNum =Math.floor(Math.round((cleanedInput*10)))/10
                } else roundedNum = Math.floor(Math.round((cleanedInput.slice(0,cleanedInput.length-1)*10)))/10
                if (roundedNum.toString().length > 11)roundedNum = parseFloat(roundedNum.toString().slice(0,11))
            } else roundedNum = Math.floor(Math.round((cleanedInput*10)))/10;
            if (roundedNum.toString().length > 10)roundedNum = parseFloat(roundedNum.toString().slice(0,10))
        }

        let newInput
            if(inputType === VALUE.MONEY && cleanedInput.length > 0) {
                if(roundedNum.toString().includes('.')) {
                newInput = roundedNum
                if(inputValue!==newInput)
                setInputValue(roundedNum)
            }
                else {newInput=cleanedInput;if(inputValue!==newInput)setInputValue(cleanedInput)}
            }
            else if (inputType === VALUE.MILES && cleanedInput.length > 0) {
                if(roundedNum.toString().includes('.'))
                {newInput=roundedNum;if(inputValue!==newInput)setInputValue(roundedNum)}
                else {newInput = cleanedInput;if(inputValue!==newInput)setInputValue(cleanedInput)}
                if(inputValue===newInput || newInput===inputValue) {setInputValue(newInput.toString().replace(/[^0-9.]+/g, ''))}

            }
            else {
            newInput ='';
            if(inputValue!==newInput)
            {setInputValue('')}
        }
        
        setFormHasValue(!(cleanedInput === '' || roundedNum <= '0'));
    }

    const handleSubmit = (event) => {
        event.preventDefault();
        let cleanedValue
        let mileMaxIndex = 12
        if(inputValue.length < 12) mileMaxIndex = mileMaxIndex - (12-inputValue.length)
        // console.log(mileMaxIndex)
        if (inputType===VALUE.MILES) cleanedValue = ((Math.round(inputValue*100)/100).toString().slice(0,4))
        else cleanedValue = parseFloat(inputValue.replace(/[^0-9.]+/g, '').replace(/(\..*)\./g, '$1'))

        let localGasPrice = state?.settings?.stateGasPrice ? state.settings.stateGasPrice : null
        let vehicles = state?.vehicles ? state?.vehicles : null
        let vehicle
        (function getVehicle(vehicle_uuid = 'uuid') {
            // vehicle_uuid must be the value of an input when input type was miles
            if (state.vehicles) {
              const { vehicleId } = vehicles[0];
              vehicle = vehicles[0];
            }
          })();
        let targets = [[0,1]]//Should be dynamically set by user

        const newInputValue = new ShayveInput(state,
            new NewShayveInput.amt(cleanedValue),_,
            new NewShayveInput.input_type(inputType),
            _,_,_,_)

        if (formHasValue) {
        add.setNewInput((prevNewInput) => ({
            ...prevNewInput,
            value: newInputValue
        }));

        // console.log(newInputValue)

            togglePopup(POPUPS.createInput);
            setInputValue('');
            setFormHasValue(false)
        }
      };
      const handleClear = () => { setInputValue('');setFormHasValue(false)}
      const handleInputBlur = () => {
        setInputFocused(false);
        // handleClear()
      };

      function hasDecimalPlaces(number) {
        return number.toString().includes('.');
      }
  return (
    <>
    <form className='w-full h-full'  onSubmit={handleSubmit}>

    <div className='absolute w-[50vw] top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2'><div className='flex flex-col justify-center items-center'>
    <h2 className='text-[28px] font-semibold mb-4'>Shayvings account</h2>
    <h1 className='text-[32px] mb-2'>${total}</h1>
    </div>
    {/* <h3>{`${gasTotal ? '$' + Math.floor(100*(gasTotal))/100 + ' worth of driving and ' : ''}`}{`${moneyTotal ? '$'+moneyTotal + ' of expenses [pi chart]' : ''}`} </h3> */}
    <div className={``}>
    {/* <h1 className='text-xl '>{'Add:'}</h1> */}
        <div className={`grid grid-cols-[repeat(3,1fr)] grid-rows-3 items-center mt-2`}>
        <label htmlFor='inputRef' className={`items-center place-self-center grid grid-cols-[1rem_4em_1rem] text-xl col-start-2 row-start-1 h-[3rem] bg-white border-white rounded-lg`}>
        {inputType===VALUE.MONEY&&<span className={`col-start-1 ml-1 text-xl z-10 bg-white`}>$</span>}
        <input id="inputRef" ref={inputRef} className={`bg-transparent text-center shave-input col-start-${inputType===VALUE.MONEY?'2':'1'} translate-x-[${inputType===VALUE.MONEY?'-8px':'8px'}] col-span-2 row-start-1`} type='text' /* placeholder={inputType ===VALUE.MONEY ? '0.00' : '0.0'} */ value={inputValue} onChange={handleInputChange} onFocus={handleInputFocus} /* onBlur={handleInputBlur} *//>
        {inputType===VALUE.MILES&&<span className=' col-start-3 translate-y-[-1px] translate-x-[-8px] mr-1 bg-white'>mi</span>}
    </label>
    {/* {inputValue && <input className='col-start-3 row-start-1 self-center place-self-end text-xs border-2 py-[0.2em] px-[0.5em] rounded-[15em] bg-[#f7f7f7]' type='button' value={'clear'} onClick={handleClear}/>} */}
        <div className='mt-2 col-start-1 row-start-2 col-span-3 flex justify-center'>
            <input className=" hidden peer/money" name='svngs-type-selector' id='money-radio' type='radio' checked={inputType===VALUE.MONEY} onChange={() => {setInputType(VALUE.MONEY); if(inputValue !== '' && hasDecimalPlaces(inputValue)) { setInputValue(parseFloat(inputValue).toFixed(2))}}}/>
            <input className=" hidden peer/miles" name='svngs-type-selector' id='miles-radio' type='radio' checked={inputType===VALUE.MILES} onChange={() => {setInputType(VALUE.MILES); if(inputValue !== 0 && inputValue !== '' && !isNaN(inputValue)) {setInputValue(Math.floor(inputValue*10)/10)}}}/>
            <label htmlFor='money-radio' onClick={toggleInputType} className='text-sm peer-checked/money:font-bold col-start-2 row-start-2 peer-checked/money:bg-[#37b743] peer-checked/money:text-white p-1 peer-checked/money:rounded-md'>Money</label>
            <label htmlFor="miles-radio" onClick={toggleInputType} className='text-sm peer-checked/miles:font-bold peer-checked/miles:bg-[#ffa82b] peer-checked/miles:text-white p-1 peer-checked/miles:rounded-md'>Miles</label>
        </div>
    <input className={`text-md mt-2 col-start-2 row-start-${3} py-2 px-3 rounded-full text-[#4ea31a] disabled:text-[#bababa] disabled:scale-75 text-lg font-bold cursor-pointer tracking-wider border-[#219114] disabled:border-[#b9b9b9] border-[2px] `} disabled={!inputValue} type='submit' value={'Submit'}/>
    </div>
    </div></div>
    </form>
    {
    isInputFocused &&
    <>
    <form onSubmit={handleSubmit} className=''>
    <div id='scaled-popup' className='scale-150 z-30 absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 bg-[buttonface] px-3 py-4'>
    <div className={``}>
    <h1 className=' text-lg'>{'Log new savings'}</h1>
        <div className={`grid grid-cols-[repeat(3,1fr)] grid-rows-3 items-center mt-2`}>
    <label htmlFor='inputRef' className={`items-center place-self-center grid grid-cols-[1rem_4em_1rem] col-start-2 row-start-1 h-[2rem] bg-white border-white rounded-lg`}>
        {inputType===VALUE.MONEY&&<span className=' col-start-1 ml-1 z-30'>$</span>}
        <input id="inputRef" ref={popInputRef} className={`bg-transparent text-center shave-input col-start-${inputType===VALUE.MONEY?'2':'1'} translate-x-[${inputType===VALUE.MONEY?'-8px':'8px'}] col-span-2 row-start-1`} type='text' /* placeholder={inputType ===VALUE.MONEY && total ? '$0' : total ? '0 miles' : ''} */ value={inputValue} onChange={handleInputChange} onFocus={handleInputFocus} /* onBlur={handleInputBlur} *//>
        {inputType===VALUE.MILES&&<span className=' col-start-3 translate-y-[-1px] translate-x-[-6px] mr-1 z-30 bg-white'>mi</span>}
    </label>
    {inputValue && <input className='col-start-3 row-start-1 self-center place-self-end' type='button' value={'x'} onClick={handleClear}/>}
    <div className='col-start-1 row-start-2 col-span-3 flex justify-center'>
            <input className=" hidden peer/money" name='svngs-type-selector' id='money-radio' type='radio' checked={inputType===VALUE.MONEY} onChange={() => {setInputType(VALUE.MONEY); if(inputValue !== '' && hasDecimalPlaces(inputValue)) { setInputValue(parseFloat(inputValue).toFixed(2))}}}/>
            <input className=" hidden peer/miles" name='svngs-type-selector' id='miles-radio' type='radio' checked={inputType===VALUE.MILES} onChange={() => {setInputType(VALUE.MILES); if(inputValue !== 0 && inputValue !== '' && !isNaN(inputValue)) {setInputValue(Math.floor(inputValue*10)/10)}}}/>
            <label htmlFor='money-radio' onClick={toggleInputType} className='text-xs col-start-2 row-start-2 peer-checked/money:bg-[#37b743] peer-checked/money:text-white p-1 peer-checked/money:rounded-md'>Money</label>
            <label htmlFor="miles-radio" onClick={toggleInputType} className='text-xs peer-checked/miles:bg-[#ffa82b] peer-checked/miles:text-white p-1 peer-checked/miles:rounded-md'>Miles</label>
        </div>    <input className={`text-md col-start-2 row-start-${3} px-2 py-1 ${formHasValue?inputType===VALUE.MONEY?'bg-[#37b743]':'bg-[#ffa82b]':'bg-[#999999]'} text-white rounded-full`} type='submit' value={'Submit'}/>
    </div>
    </div>
    </div>
    

    </form>
    <div onClick={handleInputBlur} className='bg-[#0000005e] z-20 absolute h-screen w-screen'/>
    </>
}
    </>
  );
};


export default InputForm;
